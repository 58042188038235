import { DialogContent as DialogContentContainer } from '@mui/material';
import * as React from 'react';

interface Props {
  isMobile?: boolean;
  children?: React.ReactNode;
}

export const DialogContent: React.FC<Props> = (props) => {
  return (
    <DialogContentContainer
      sx={{
        overflow: 'visible',
        margin: props.isMobile ? '0 18px' : '0 24px',
        padding: '0'
      }}
      data-cy="dialog-content"
    >
      {props.children}
    </DialogContentContainer>
  );
};
