import {
  Action,
  Group,
  OrganizationRoleResolver,
  Role,
  User,
  isBankUser,
  isDispensaryUser,
  isGcvUser
} from '@gcv/shared';
import { NavigateFunction } from 'react-router-dom';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { getFiTemplateStore } from 'stores/FiTemplateStore';
import { getGcvAdminStore } from 'stores/GcvAdminStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { deleteRedirectUrl, getRedirectUrl } from 'util/auth0-storage.util';

/**
 * Redirect user to the desired path.
 *
 * @param pathNameGuard substring that must be found in current path.
 * @param defaultPathName path to use when none is stored.
 * @param history React history object.
 */
const redirect = (pathNameGuard: string, defaultPathName: string, navigate: NavigateFunction): void => {
  const storedPathName = getRedirectUrl(true);

  if (storedPathName?.includes(pathNameGuard)) {
    navigate(storedPathName);
    deleteRedirectUrl();
  } else {
    const pathName = window.location.pathname.includes(pathNameGuard)
      ? window.location.pathname + window.location.search
      : defaultPathName;

    navigate(pathName);
  }
};

const getRolesForPath = (path: string): Role[] => {
  // If current path includes the key, return roles
  // Update as you update the regex checks below
  // const pathRoles: Record<string, Role[]> = {
  //   'provider-onboarding': ['dispensary_account_owner'],
  //   'login/support': ['gcv_customer_support']
  // };
  const roles: Role[] = [];
  if (path.match(/provider-onboarding/g)) {
    roles.push('dispensary_account_owner');
  } else if (path.match(/login\/support/g)) {
    roles.push('gcv_customer_support');
  }
  return roles;
};

const getActionForPath = (path: string): Action | undefined => {
  // If current path includes the key, return action
  // Update as you update the regex checks
  // const pathActions: Record<string, Action> = {
  //   'dashboard/deposit': 'deposit_view_all',
  //   marketplace: 'organizational_details_update',
  //   'my-providers': 'organizational_details_update',
  //   company: 'organizational_details_update'
  // };
  if (path.match(/dashboard\/deposit/g)) {
    return 'deposit_view_all';
  } else if (path.match(/marketplace|my-providers|company/g)) {
    return 'organizational_details_update';
  } else if (path.match(/users/g)) {
    return 'user_add';
  }
  return;
};

export const userHasRole = (orgGroups: Group[], user: User, path: string): boolean => {
  const roles = getRolesForPath(path);
  const roleResolver: OrganizationRoleResolver = new OrganizationRoleResolver();
  let hasRole = true;

  if (roles) {
    roles.forEach((role) => {
      hasRole = hasRole && roleResolver.userHasRole(orgGroups, user, role);
    });
  }

  return hasRole;
};

export const userCanDoAction = (orgGroups: Group[], user: User, path: string): boolean => {
  const action = getActionForPath(path);
  const roleResolver: OrganizationRoleResolver = new OrganizationRoleResolver();
  const hasAction = action ? roleResolver.userCanDoAction(orgGroups, user, action) : true;

  return hasAction;
};

export const initializeLoggedInUser = async (
  navigate: NavigateFunction,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void,
  url?: string
) => {
  try {
    if (!userStore.isLoaded || userStore.user.invitation_status === 'archived') {
      return;
    }

    const role = getUserType(userStore.user);

    switch (role) {
      case 'bank': {
        await initFi(navigate, userStore, setIsLoading);
        break;
      }
      case 'dispensary': {
        // Todo remove the empty array when we deprecate lendica org IDs. This is okay right now because we aren't even using it in production
        await initCrb(navigate, userStore, setIsLoading, [], url);
        break;
      }
      case 'gcv': {
        await initGcv(navigate, userStore, setIsLoading);
        break;
      }
    }
  } catch (e) {
    setIsLoading(false);
    getSnackbarStore().showErrorSnackbarMessage(
      'Something went wrong! Please contact support if the problem persists.'
    );
    navigate('/error');
  }
};

export const getUserType = (user: User): 'bank' | 'dispensary' | 'gcv' => {
  if (isGcvUser(user)) {
    if (user.role === 'gcv_customer_support') {
      return user.companies[0].companyType;
    } else {
      return 'gcv';
    }
  } else if (isBankUser(user)) {
    return 'bank';
  } else if (isDispensaryUser(user)) {
    return 'dispensary';
  } else {
    throw new Error(`User ${user.id} type is not defined: ${user.role}`);
  }
};

export const initGcv = async (
  navigate: NavigateFunction,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void
) => {
  try {
    setIsLoading(true);
    const gcvAdminStore = getGcvAdminStore();
    await gcvAdminStore.loadStore();
    redirect('/secure/gcv/', '/secure/gcv/dashboard', navigate);
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};

export const initFi = async (
  navigate: NavigateFunction,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void
) => {
  try {
    setIsLoading(true);
    const bankStore = getFiBankStore();
    const bankDispensaryStore = getFiDispensaryStore();
    const bankTemplateStore = getFiTemplateStore();

    await bankStore.loadBank(userStore.user.companies[0].id);

    await Promise.all([bankTemplateStore.loadTemplates(), bankDispensaryStore.loadAllOrganizations()]);

    redirect('/secure/fi/', '/secure/fi/dashboard', navigate);
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};

export const initCrb = async (
  navigate: NavigateFunction,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void,
  lendicaOrgIds: string[],
  url?: string
) => {
  try {
    setIsLoading(true);
    const dispensaryStore = getCrbDispensaryStore();
    const user = userStore.user;

    if (!user.companies.length && user.marketing_user_in_progress) {
      redirect('/secure/crb/', '/secure/crb/welcome', navigate);
      return;
    }

    await dispensaryStore.loadDispensaries(
      user.companies.map(({ id }) => id),
      lendicaOrgIds,
      user.id
    );

    if (dispensaryStore.isLoaded && dispensaryStore.invitedDispensaries.length !== 0) {
      navigate('/secure/crb/connect-org');
    }
    let crbId;

    if (url) {
      const regex = /crb\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
      const match = url.match(regex);

      if (match) {
        crbId = match[1];
      }
    }

    //check local storage to see if MUO user had a previous selected dispensary
    const previousSelectedDispensary = window.sessionStorage.getItem('currentDispensaryId');
    const isPreviouslySelectedDispensary = Boolean(
      previousSelectedDispensary && user.companies.some(({ id }) => id === previousSelectedDispensary)
    );
    const currentDispensary = crbId
      ? crbId
      : isPreviouslySelectedDispensary
      ? previousSelectedDispensary
      : user.companies[0].id;

    await dispensaryStore.setCurrentDispensary(currentDispensary ?? '');

    if (!user.termsOfService && user.initial_user && user.role !== 'gcv_customer_support') {
      navigate('/tos');
    } else {
      redirect('/secure/crb/', '/secure/crb/dashboard', navigate);
    }
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};
