import { injectable } from 'inversify';
import { api } from './api-util/api';

@injectable()
export class QuickbooksApi {
  async getQuickbooksAccessToken(
    dispensaryId: string,
    path: { uri: string }
  ): Promise<{ accessToken: { access_token: string } }> {
    return await api().post(`/quickbooks/${dispensaryId}/accessToken`, path);
  }

  async getQuickbooksAuthUri(): Promise<{ authUri: string | undefined }> {
    return await api().get(`/quickbooks/authUri`);
  }
}
