import { Box, Grid } from '@mui/material';
import * as React from 'react';

interface Props {
  paddingNone?: boolean;
  justifyContent?: 'left' | 'center' | 'right' | 'space-between' | 'space-around';
  direction?: 'row' | 'column';
  dataCy?: string;
  width?: string;
  parentPaddingBottom?: string;
  children?: React.ReactNode;
}

export const View: React.FC<Props> = (props) => {
  const direction = props.direction ? props.direction : 'row';

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        width: props.width ? props.width : 'none',
        paddingBottom: props.parentPaddingBottom ? props.parentPaddingBottom : '48px'
      }}
    >
      <Grid
        container
        data-cy={props.dataCy}
        direction={direction}
        spacing={props.paddingNone ? 0 : 2}
        sx={{ height: '100%', justifyContent: props.justifyContent }}
      >
        {props.children}
      </Grid>
    </Box>
  );
};
