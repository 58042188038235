import { Box, Drawer as DrawerContainer } from '@mui/material';
import * as React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { Container, ContainerItem, Divider, Header, Text, Tooltip } from 'ui/atoms';

interface Props {
  action?: React.ReactNode;
  anchor?: 'left' | 'right';
  bottomAction?: React.ReactNode;
  bottomActionPadding?: string;
  dataCy?: string;
  defaultPadding?: boolean;
  hideBackdrop?: boolean;
  id?: string;
  onClose: () => void;
  open: boolean;
  permanent?: boolean;
  subtitle?: string;
  title?: string;
  width?: string;
  bottomActionMargin?: string;
  children?: React.ReactNode;
}

export const Drawer: React.FC<Props> = (props) => {
  const anchor = props.anchor ? props.anchor : 'right';
  const drawerPadding = props.defaultPadding ? '24px' : '0';
  const drawerWidth = props.width ? props.width : anchor === 'left' ? '261px' : '482px';
  const titleCutOff = props.width && Number.parseInt(props.width) > 600 ? 60 : 27;
  const variant = props.permanent ? 'permanent' : 'temporary';

  return (
    <DrawerContainer
      disableEnforceFocus //this prop is required because the drawer steals focus from input boxes
      anchor={anchor}
      data-cy={props.dataCy}
      hideBackdrop={props.hideBackdrop}
      id={props.id}
      onClose={() => props.onClose()}
      open={props.open}
      sx={{
        flexShrink: 0,
        width: drawerWidth,

        '& .MuiDrawer-paper': {
          borderLeft: (theme) => (anchor === 'right' ? `1px solid ${theme.palette.grey[400]}` : 'none'),
          borderRight: (theme) => (anchor === 'left' ? `1px solid ${theme.palette.grey[400]}` : 'none'),
          height: '100%',
          overflowX: 'hidden',
          padding: drawerPadding,
          paddingBottom: props.bottomAction ? '0' : '100px',
          width: drawerWidth
        }
      }}
      variant={variant}
    >
      {anchor === 'right' && (
        <Container align="center" padding="0" width="100%">
          <Container width="100%" padding="16px">
            <ContainerItem width="70%" grow padding="0">
              {props.title && (
                <Tooltip title={props.title} disabled={props.title.length < titleCutOff + 1}>
                  <Header dataCy="drawer-title" type="h2" content={props.title} textOverflow="ellipsis" />
                </Tooltip>
              )}
              {props.subtitle && (
                <Tooltip title={props.subtitle} disabled={props.subtitle.length < 35}>
                  <Text
                    dataCy="drawer-subtitle"
                    content={
                      props.subtitle
                        ? props.subtitle.length > 34
                          ? props.subtitle.substring(0, 33) + '...'
                          : props.subtitle
                        : ''
                    }
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: '16px',
                      pt: '4px',
                      width: '90%'
                    }}
                    textOverflow="ellipsis"
                  />
                </Tooltip>
              )}
            </ContainerItem>

            {props.action && <ContainerItem padding="0">{props.action}</ContainerItem>}

            <ContainerItem flex padding="0">
              <CloseIcon onClick={props.onClose} style={{ cursor: 'pointer' }} />
            </ContainerItem>
          </Container>

          <ContainerItem flex margin={'0 -24px'} padding="0" width="calc(100% + 48px)">
            <Divider />
          </ContainerItem>
        </Container>
      )}

      {props.bottomAction ? (
        <Container padding={0} width="100%">
          <Box
            sx={{
              height: props.bottomActionMargin ? 'calc(100vh - 185px)' : 'calc(100vh - 144px)',
              overflow: 'auto',
              width: drawerWidth
            }}
          >
            <ContainerItem padding={0} width="100%">
              {props.children}
            </ContainerItem>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              bottom: 0,
              justifyContent: 'center',
              margin: props.bottomActionMargin ? props.bottomActionMargin : '0',
              position: 'fixed',
              width: drawerWidth
            }}
          >
            <ContainerItem textAlign="center" padding="0" width="100%">
              <Box
                sx={{
                  padding: '0.5rem 0',
                  width: '100%'
                }}
              >
                <Divider />
              </Box>

              {props.bottomAction}
            </ContainerItem>
          </Box>
        </Container>
      ) : (
        <Container padding={0} width="100%">
          <ContainerItem padding={0} width="100%">
            {props.children}
          </ContainerItem>
        </Container>
      )}
    </DrawerContainer>
  );
};
