import { IANATimezones } from '@gcv/shared';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Control, Controller, FieldValues, Validate } from 'react-hook-form';
import { Container, ContainerItem, Error, Icon, InputLabel, Tooltip, Text } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputDateProps {
  background?: 'white' | 'gray';
  color?: string;
  control?: Control;
  dataCy?: string;
  label: string;
  name: string;
  onBlur?: () => void;
  onChange?: (e: any) => void;
  readOnly?: boolean;
  rules?: {
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    required?: ValidationRule<boolean>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  timezone: IANATimezones;
  tooltip?: string;
  viewOnly?: boolean;
  viewFormat?: string;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  minDate?: DateTime;
  darkLabel?: boolean;
}

export const InputDate: React.FC<InputDateProps> = (props) => {
  // Track when a typed in date is invalid.
  const [hasNullRender, setNullRender] = React.useState(false);
  const [inputError, setInputError] = React.useState<string>();
  const background = props.background ? props.background : 'gray';

  React.useEffect(() => {
    setInputError(undefined);
  }, [props.viewOnly, props.readOnly]);

  const getStartOfDay = () => {
    return DateTimeHelpers.getStartOfTodayIsoString(props.timezone);
  };

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      {props.label.trim().length > 0 && (
        <Container padding={0} align="flex-start">
          <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
            <InputLabel
              dataCy={'input-label'}
              label={props.label}
              name={props.name}
              required={props.viewOnly ? false : props.rules?.required?.value}
              readOnly={props.readOnly}
              rules={props.rules}
              viewOnly={props.viewOnly}
              darkLabel={props.darkLabel}
            />
          </ContainerItem>
          {props.tooltip ? (
            <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
              <Tooltip variant="text" title={props.tooltip} placement="right">
                <Icon type="help" color="primary" />
              </Tooltip>
            </ContainerItem>
          ) : null}
        </Container>
      )}

      <Controller
        control={props.control}
        name={props.name}
        rules={{
          validate: (value: string | number) => {
            if (props.disablePastDates) {
              const valid = getStartOfDay() <= value.toString();
              return valid || 'cannot be past date.';
            } else if (props.disableFutureDates) {
              const valid = getStartOfDay() >= value.toString();
              return valid || 'cannot be future date.';
            } else {
              return true;
            }
          },
          ...props.rules
        }}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
          const handleOnBlur = () => {
            if (props.onBlur) {
              props.onBlur();
            }

            onBlur();
          };

          let dateString = '--';

          //we want the value of this to always be a DateTime object to maintain timezone
          if (typeof value !== 'object') {
            if (props.viewOnly) {
              dateString =
                !value || value === 'Invalid DateTime' || value === '--'
                  ? '--'
                  : value && props.viewFormat
                  ? DateTimeHelpers.formatISODateStringToDateString(value, props.timezone, props.viewFormat)
                  : value && DateTimeHelpers.parseFromISOString(value, props.timezone).isValid
                  ? DateTimeHelpers.formatISOToDateString(value, props.timezone)
                  : value && DateTimeHelpers.parseFromTableDateString(value, props.timezone).isValid
                  ? DateTimeHelpers.formatISOToTableDateString(value, props.timezone)
                  : value && DateTimeHelpers.parseFromDateString(value, props.timezone).isValid
                  ? DateTimeHelpers.formatISOToDateString(value, props.timezone)
                  : value.toString().trim().length > 0
                  ? value.toString()
                  : '--';
            }

            value =
              !value || value === 'Invalid DateTime' || value === '--'
                ? null
                : value && DateTimeHelpers.parseFromISOString(value, props.timezone).isValid
                ? DateTimeHelpers.parseFromISOString(value, props.timezone)
                : value && DateTimeHelpers.parseFromTableDateString(value, props.timezone).isValid
                ? DateTimeHelpers.parseFromTableDateString(value, props.timezone)
                : value && DateTimeHelpers.parseFromDateString(value, props.timezone).isValid
                ? DateTimeHelpers.parseFromDateString(value, props.timezone)
                : value;
          }

          if (value && hasNullRender) {
            // We want to clear an existing error message without any further clicks, when a
            // user has corrected the date input via the calendar widget.
            setNullRender(false);
            handleOnBlur();
          } else if (value === null) {
            // A null happens when the user has typed in an invalid date.
            setNullRender(true);
          }

          return props.viewOnly ? (
            <Text content={dateString || '--'} type="body1" sx={{ color: props.color }} dataCy="date-text" />
          ) : (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                //allowSameDateSelection={true}
                minDate={props.minDate}
                disablePast={props.minDate ? false : props.disablePastDates}
                disableFuture={props.disableFutureDates}
                disabled={props.readOnly || props.viewOnly}
                //inputFormat="MM/dd/yyyy"
                onChange={(e: any) => {
                  onChange(
                    e?.setZone(props.timezone, { keepLocalTime: true }).startOf('day').toUTC().toISO()
                  );

                  if (e?.invalid && e.invalid.reason && e.invalid.reason === 'unit out of range') {
                    setInputError('is invalid');
                  } else {
                    setInputError(undefined);
                  }

                  if (props.onChange) {
                    props.onChange(e);
                  }
                }}
                sx={{
                  color: (theme) =>
                    props.readOnly ? theme.palette.text.secondary : theme.palette.text.primary,
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '20px',

                  '.MuiInputBase-root': {
                    backgroundColor: props.background
                      ? props.background
                      : (theme) => theme.palette.background.default,
                    border: `1px solid`,
                    borderColor: (theme) => (error ? theme.palette.error.main : theme.palette.divider),
                    borderRadius: '5px',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',

                    WebkitTextFillColor: (theme) =>
                      props.readOnly ? theme.palette.text.secondary : theme.palette.text.primary,

                    '&:hover': {
                      cursor: props.readOnly ? 'not-allowed' : 'default'
                    },

                    '&.Mui-focused': {
                      border: `1px solid`,
                      borderColor: (theme) => (error ? theme.palette.error.main : theme.palette.primary.main),
                      boxShadow: error
                        ? `0px 0px 0px 3px rgba(255, 78, 80, 0.25)`
                        : `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
                    },

                    '& fieldset': {
                      border: 'none'
                    }
                  },

                  '.MuiInputAdornment-root': {
                    '& button': {
                      color: (theme) =>
                        props.readOnly ? theme.palette.text.secondary : theme.palette.text.primary
                    }
                  },

                  '.MuiInputBase-input': {
                    border: 'none',
                    padding: '0.5rem 0.75rem',

                    '&.Mui-disabled': {
                      WebkitTextFillColor: (theme) =>
                        props.readOnly ? theme.palette.text.secondary : theme.palette.text.primary
                    },

                    '&:hover': {
                      cursor: props.readOnly ? 'not-allowed' : 'default'
                    }
                  }
                }}
                value={value}
              />
              <Error
                content={
                  error
                    ? `* ${props.label} ${error.message || errorMessages()[error.type]}`
                    : inputError
                    ? `* ${props.label} ${inputError}`
                    : ''
                }
                dataCy="validation-message"
              />
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
};
