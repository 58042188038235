import { urlValidation } from 'domain/consts/input-validation';
import * as React from 'react';
import { Control, FieldValues, Validate } from 'react-hook-form';
import { Container, InputLabel } from 'ui/atoms';
import { InputText } from 'ui/molecules';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputUrlProps {
  label: string;
  name: string;
  id?: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  placeholder?: string;
  viewOnly?: boolean;
  tooltip?: string | React.ReactElement;
  darkLabel?: boolean;
}

export const InputUrl: React.FC<InputUrlProps> = (props) => {
  /** Returning href component here else have to loop
   * through the rules for url validation pattern and
   * add the href inside the InputText component
   * */
  if (props.viewOnly) {
    const formValue = props.control?._formValues[props.name];
    const prefix = formValue?.indexOf('http') === -1 ? '//' : '';

    return (
      <Container padding={0} column dataCy={props.dataCy}>
        <InputLabel
          label={props.label}
          dataCy={'input-label'}
          name={props.name}
          readOnly={props.readOnly}
          rules={props.rules}
          viewOnly={props.viewOnly}
          darkLabel={props.darkLabel}
        />
        {formValue === null || formValue === undefined || formValue === '' ? (
          '--'
        ) : (
          <a
            href={formValue ? prefix + formValue : undefined}
            target="_blank"
            style={{ padding: '6px 0 7px' }}
            data-cy="url-link"
          >
            {formValue}
          </a>
        )}
      </Container>
    );
  }
  const { rules, invalidErrorMessage, placeholder, ...rest } = props;

  return (
    <InputText
      {...rest}
      onBlur={props.onBlur}
      placeholder={placeholder || 'http://'}
      rules={{
        ...rules,
        pattern: {
          message:
            invalidErrorMessage ||
            'invalid. URL must be in an acceptable format (ex. google.com or www.google.com).',
          value: urlValidation
        }
      }}
      tooltip={props.tooltip}
      dataCy={props.dataCy}
    />
  );
};
