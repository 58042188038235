import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { FieldValues } from 'react-hook-form';
import {
  NotificationPreference,
  NotificationPreferences,
  NotificationTypes,
  OrganizationRoleResolver,
  UserInterests
} from '@gcv/shared';
import { CrbUserSettingsRepo, PM, UserInfo } from './crb-user-settings.repo';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { UserStore } from 'stores/UserStore';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type { UserInfo } from './crb-user-settings.repo';

export interface NotificationData {
  name: NotificationTypes;
  description: string;
  hasInApp: boolean;
  hasEmail: boolean;
}

export interface FormData extends FieldValues {
  userInfo: UserInfo;
  settings: Record<keyof VM['settings'], NotificationData>;
  interests: { [key in UserInterests]: boolean };
}

export class NotificationSetting implements NotificationData {
  static _preferences: NotificationPreferences | undefined;

  name: NotificationTypes;
  description: string;
  tooltip?: string;
  disableInAppToggle: boolean;
  disableEmailToggle: boolean;
  hasInApp: boolean;
  hasEmail: boolean;

  static initialize(preferences: NotificationPreferences) {
    NotificationSetting._preferences = preferences;
  }

  get preferences() {
    if (NotificationSetting._preferences === undefined) {
      throw new Error('Class is not initialized.');
    }

    return NotificationSetting._preferences;
  }

  constructor(
    name: NotificationTypes,
    description: string,
    disableToggle: 'inApp' | 'email' | 'inAppAndEmail' | '' = '',
    tooltip?: string
  ) {
    this.name = name;
    this.description = description;
    this.tooltip = tooltip;
    this.disableInAppToggle = disableToggle === 'inApp' || disableToggle === 'inAppAndEmail';
    this.disableEmailToggle = disableToggle === 'email' || disableToggle === 'inAppAndEmail';

    const preference = this.preferences[name];

    this.hasInApp =
      preference === NotificationPreference.EmailAndApp || preference === NotificationPreference.App;
    this.hasEmail =
      preference === NotificationPreference.EmailAndApp || preference === NotificationPreference.Email;
  }

  public getNotificationPreference() {
    if (this.hasInApp && this.hasEmail) {
      return NotificationPreference.EmailAndApp;
    } else if (this.hasInApp) {
      return NotificationPreference.App;
    } else if (this.hasEmail) {
      return NotificationPreference.Email;
    } else {
      return NotificationPreference.None;
    }
  }
}

export interface VM {
  isLoading: boolean;
  userInfo: UserInfo;
  interests: { [key in UserInterests]: boolean };
  settings: {
    accounts: NotificationSetting[];
    salesIntegration: NotificationSetting[];
    documents: NotificationSetting[];
    reports: NotificationSetting[];
    licenses: NotificationSetting[];
    comments: NotificationSetting[];
    maxDeposits: NotificationSetting[];
    questionnaireTasks: NotificationSetting[];
    crbInsights: NotificationSetting[];
  };
}

@injectable()
export class CrbUserSettingsPresenter {
  @inject(CrbUserSettingsRepo)
  private repo: CrbUserSettingsRepo;
  @inject(UserStore) private userStore: UserStore;
  @inject(CrbDispensaryStore) private dispensaryStore: CrbDispensaryStore;

  public viewModel: VM = {
    isLoading: true,
    userInfo: {},
    interests: {
      [UserInterests.AchAndWires]: false,
      [UserInterests.BillPay]: false,
      [UserInterests.BusinessBanking]: false,
      [UserInterests.BusinessInsurance]: false,
      [UserInterests.CashLogistics]: false,
      [UserInterests.CommercialLoans]: false,
      [UserInterests.PayrollAndHr]: false,
      [UserInterests.Payments]: false
    },
    settings: {
      accounts: [],
      salesIntegration: [],
      documents: [],
      reports: [],
      licenses: [],
      comments: [],
      maxDeposits: [],
      questionnaireTasks: [],
      crbInsights: []
    }
  };

  constructor() {
    makeAutoObservable(this);
  }

  public load = action(async (editCrbInfo: boolean) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      NotificationSetting.initialize(programmersModel.notificationPreferences);

      // For each group of settings, this will be the order the UI presents the settings in.
      const accounts = [
        new NotificationSetting(NotificationTypes.COMPLETED_DISPENSARY_REVIEW, 'Account approved'),
        new NotificationSetting(NotificationTypes.BANKING_PARTNER_IDENTIFIED, 'Banking partner identified'),
        new NotificationSetting(
          NotificationTypes.CRBM_LICENSE_STATUS_UPDATE,
          'Marijuana license status change'
        )
      ];

      if (editCrbInfo) {
        accounts.push(
          new NotificationSetting(
            NotificationTypes.FI_EDIT_INFO,
            'Your company profile has been modified',
            '',
            'In-app notifications will be sent as individual changes occur; however, email alerts will be batched and delivered the next morning to limit the number of emails received.'
          )
        );
      }

      const salesIntegration = [
        new NotificationSetting(NotificationTypes.SALES_INGESTION_FAILURE, 'Sales ingestion failure'),
        new NotificationSetting(
          NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_NON_RETAIL,
          'Sales ingestion - partial success (non-retail)'
        ),
        new NotificationSetting(
          NotificationTypes.SALES_INGESTION_PARTIAL_SUCCESS_RETAIL,
          'Sales ingestion - partial success (retail)'
        )
      ];

      const documents = [
        new NotificationSetting(NotificationTypes.ACTIVITY_REPORTING, 'Report available for download'),
        new NotificationSetting(
          NotificationTypes.NEW_DOCUMENT_UPLOADED_FOR_CRB,
          'New document has been uploaded'
        )
      ];

      const reports = [
        new NotificationSetting(NotificationTypes.NEW_FINCEN_REPORT, 'New FinCEN report available')
      ];

      const licenses = [
        new NotificationSetting(NotificationTypes.EXPIRED_BUSINESS_LICENSE, 'Expired business license'),
        new NotificationSetting(NotificationTypes.EXPIRED_EMPLOYEE_LICENSE, 'Expired employee license'),
        new NotificationSetting(NotificationTypes.EXPIRING_BUSINESS_LICENSE, 'Business license is expiring'),
        new NotificationSetting(NotificationTypes.EXPIRING_EMPLOYEE_LICENSE, 'Employee license is expiring'),
        new NotificationSetting(NotificationTypes.COMPLETE_EVS, 'Identity verification requested')
      ];

      const comments = [
        new NotificationSetting(NotificationTypes.COMMENT_REPLY, 'Comment replies (replies to your comment)'),
        new NotificationSetting(
          NotificationTypes.COMMENT_MENTION,
          'Comment mentions (comments that mention you)'
        )
      ];

      const maxDeposits = [
        new NotificationSetting(
          NotificationTypes.MAX_DEPOSIT_UPDATED,
          'Max Deposit updated by banking partner'
        )
      ];

      const questionnaireTasks = [
        new NotificationSetting(
          NotificationTypes.ONE_TIME_QUESTIONNAIRE,
          'New questionnaires that have been assigned'
        ),
        new NotificationSetting(NotificationTypes.CRB_TASK_ASSIGNED, 'New tasks that have been assigned'),
        new NotificationSetting(
          NotificationTypes.COMMENT_TASK_ASSIGNED,
          'A comment task has been assigned to you'
        )
      ];

      const crbInsights = [
        new NotificationSetting(NotificationTypes.CRB_INSIGHTS, 'Analytics alerts', 'inApp')
      ];

      this.viewModel.userInfo = { ...programmersModel.userInfo };
      this.viewModel.settings = {
        accounts,
        salesIntegration,
        documents,
        reports,
        licenses,
        comments,
        maxDeposits,
        questionnaireTasks,
        crbInsights
      };
      this.viewModel.interests = { ...programmersModel.interests };
    });

    await this.repo.load();
    this.viewModel.isLoading = false;
  });

  public updateIsLoading = action((isLoading: boolean) => {
    this.viewModel.isLoading = isLoading;
  });

  public updateUserSettings = async (data: FormData) => {
    const userInfo = data.userInfo as UserInfo;
    const notifications = {} as NotificationPreferences;

    const keys = Object.keys(data.settings) as unknown as (keyof VM['settings'])[];

    keys
      .map((key) => {
        return data.settings[key] as NotificationData;
      })
      .flat()
      .forEach((config) => {
        notifications[config.name] =
          config.hasInApp && config.hasEmail
            ? NotificationPreference.EmailAndApp
            : config.hasInApp
            ? NotificationPreference.App
            : config.hasEmail
            ? NotificationPreference.Email
            : NotificationPreference.None;
      });

    return await this.repo.updateUserSettings({
      userInfo: userInfo,
      notificationPreferences: notifications,
      interests: data.interests
    });
  };

  public userIsAdminForSomeCrb = () => {
    const roleResolver = new OrganizationRoleResolver();
    for (const key in this.dispensaryStore.dispensaries) {
      if (
        roleResolver.userHasRole(
          this.dispensaryStore.dispensaries[key].groups,
          this.userStore.user,
          'dispensary_admin'
        )
      ) {
        return true;
      }
    }
    return false;
  };
}
