import { DocumentUpload, TemplateResponse } from '@gcv/shared';
import { DocumentsApi } from 'api';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { SnackbarStore } from 'stores/SnackBarStore';
import { uploadOrgDocumentToS3 } from 'util/s3.util';

export interface FileMap {
  [fieldId: string]: string[];
}

export const useAdditionalInfo = (
  form: UseFormReturn<FieldValues, any>,
  allDocumentsMap: {
    [id: string]: DocumentUpload;
  }
) => {
  const documentsApi = new DocumentsApi();

  const snackbarStore = useInjection(SnackbarStore);

  const [existingFilesIdsMap, setExistingFilesIdsMap] = React.useState<FileMap>({});
  const [refreshFileComponent, setRefreshFileComponent] = React.useState(false);

  // adds existing files to map
  const addExistingFilesToMap = (fieldId: string, docIDs: string[]) => {
    const data = existingFilesIdsMap;
    data[fieldId] = docIDs;
    setExistingFilesIdsMap(data);
  };

  const uploadOnboardingTemplateDocument = async (
    dispensaryId: string,
    file: File
  ): Promise<DocumentUpload | undefined> => {
    try {
      const docId = await uploadOrgDocumentToS3(file, dispensaryId);
      const document = await documentsApi.putDocument(file.name, docId, dispensaryId);

      allDocumentsMap[document.id] = document;

      return document;
    } catch (error) {
      snackbarStore.showErrorSnackbarMessage(
        'An error has occurred while uploading the file.  Please contact support for assistance.'
      );
    }
  };

  // upload new files to s3 and submit form data
  const onFilesChange = async (dispensaryId: string, fieldId: string, files: File[], onSave?: () => void) => {
    const promises = [];
    const docs: DocumentUpload[] = [];

    setRefreshFileComponent(true);
    // uploads file
    const fileUpload = async (file: File) => {
      const doc = await uploadOnboardingTemplateDocument(dispensaryId, file);
      if (doc) docs.push(doc);
    };

    for (const file of files) {
      promises.push(fileUpload(file));
    }

    await Promise.all(promises);

    const ids = docs.map((doc) => {
      allDocumentsMap[doc.id] = doc;
      return doc.id;
    });

    if (existingFilesIdsMap[fieldId]) {
      form.setValue(fieldId, [...existingFilesIdsMap[fieldId], ...ids]);
    } else {
      form.setValue(fieldId, [...ids]);
    }

    form.trigger(fieldId);

    setRefreshFileComponent(false);

    if (onSave) {
      onSave();
    }
  };

  return {
    addExistingFilesToMap,
    onFilesChange,
    existingFilesIdsMap,
    setExistingFilesIdsMap,
    refreshFileComponent
  };
};
