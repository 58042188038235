import * as React from 'react';
import { CardContent as CardContentContainer } from '@mui/material';

interface Props {
  alignContent?: 'left' | 'center' | 'right';
  maxWidth?: string;
  padding?: string;
  overflow?: string;
  noPadding: boolean;
  children?: React.ReactNode;
}

export const CardContent: React.FC<Props> = (props) => {
  const alignContent = props.alignContent ? props.alignContent : 'left';
  return (
    <CardContentContainer
      sx={{
        height: '100%',
        maxWidth: props.maxWidth ? props.maxWidth : '100%',
        overflow: props.overflow ? props.overflow : 'auto',
        p: props.noPadding ? '0' : props.padding ? props.padding : '17px 24px 0 24px',
        textAlign: alignContent
      }}
    >
      {props.children}
    </CardContentContainer>
  );
};
