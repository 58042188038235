import { ZipMask } from 'domain/consts/input-masks';
import { zipValidation } from 'domain/consts/input-validation';
import * as React from 'react';
import { Control, FieldValues, Validate } from 'react-hook-form';
import { InputMasked } from '../InputMasked/input-masked.molecule';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputZipProps {
  id?: string;
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  placeholder?: string;
  viewOnly?: boolean;
  tooltip?: string | React.ReactElement;
}

export const InputZip: React.FC<InputZipProps> = (props) => {
  const { rules, invalidErrorMessage, placeholder, ...rest } = props;

  return (
    <InputMasked
      mask={ZipMask}
      rules={{
        ...rules,
        pattern: {
          message: invalidErrorMessage || 'must be a valid zip code.',
          value: zipValidation
        }
      }}
      placeholder={placeholder || '11111'}
      tooltip={props.tooltip}
      {...rest}
    />
  );
};
