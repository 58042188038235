import { emailValidation } from 'domain/consts/input-validation';
import * as React from 'react';
import { Control, FieldValues, Validate } from 'react-hook-form';
import { InputText } from 'ui/molecules';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputEmailProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  placeholder?: string;
  tooltip?: string;
  viewOnly?: boolean;
  disableAutoWidth?: boolean;
  darkLabel?: boolean;
}

export const InputEmail: React.FC<InputEmailProps> = (props) => {
  const { rules, invalidErrorMessage, placeholder, ...rest } = props;

  return (
    <InputText
      {...rest}
      onBlur={props.onBlur}
      onChange={(e) => (e.target.value = e.target.value.trim())}
      placeholder={placeholder || 'email@example.com'}
      rules={{
        ...rules,
        pattern: {
          message:
            invalidErrorMessage || 'invalid. Email address must contain an @ symbol (ex. email@example.com).',
          value: emailValidation
        }
      }}
      darkLabel={props.darkLabel}
      tooltip={props.viewOnly ? undefined : props.tooltip}
    />
  );
};
