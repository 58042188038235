import * as React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { Box } from '@mui/system';

interface Props {
  activeSlide: number;
  dataCy?: string;
  children: React.ReactNode[];
}

export const Carousel: React.FC<Props> = (props) => {
  const options: EmblaOptionsType = {
    startIndex: props.activeSlide
  };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(props.activeSlide, false);
    }
  }, [props.activeSlide]);
  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        animation: 'translate3d 0.2s ease',
        '& .embla__container': {
          touchAction: 'pan-y',
          display: 'flex'
        },
        '& .embla__slide': {
          flex: '0 0 100%',
          minWidth: '0'
        }
      }}
    >
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {props.children.map((child, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">{child}</div>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};
