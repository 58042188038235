import { FormControl } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldError, FieldValues, Validate } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Container, ContainerItem, Error, Icon, Input, InputLabel, Text, Tooltip } from 'ui';
import { formatPercentage, formatPercentageInteger } from 'util/format.util';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputNumberProps {
  label: string;
  name: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e?: number) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  tooltip?: string | React.ReactElement;
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  bold?: true;
  placeholder?: string;
  percentage?: boolean;
  unformatted?: boolean;
  customInput?: React.ComponentType<any>;
  maxInteger?: number;
  isIntegerOnly?: boolean;
  allowNegative?: boolean;
  onError?: (error: string) => void;
}

export const InputNumber: React.FC<InputNumberProps> = (props) => {
  const [fieldError, setFieldError] = React.useState<FieldError>();

  const percentageAsInteger = props.percentage && props.isIntegerOnly;
  const formatToPercentage = percentageAsInteger ? formatPercentageInteger : formatPercentage;

  const isAllowed = (numberValue: NumberFormatValues) => {
    let isValid = true;

    // allow entering negative sign
    if (numberValue.value === '-') {
      return isValid;
    }

    if (!numberValue.value) {
      // empty is still valid, but we also want an error message
      isValid = true;
      setFieldError({
        message: `${props.label} invalid. Number field must contain numbers only (ex. 123).`,
        type: 'valueAsNumber'
      });
    } else if (isNaN(+numberValue.value)) {
      isValid = false;
      setFieldError({
        message: `${props.label} invalid. Number field must contain numbers only (ex. 123).`,
        type: 'valueAsNumber'
      });
    } else if (+numberValue.value >= (props.maxInteger ? props.maxInteger : Number.MAX_SAFE_INTEGER)) {
      isValid = false;
      setFieldError({
        message: `${props.label} invalid. Maximum number exceeded.`,
        type: 'valueAsNumber'
      });
    } else {
      setFieldError(undefined);
    }

    return isValid;
  };

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      {props.label.length ? (
        <Container padding={0} align="flex-start">
          <ContainerItem padding={0} align="center" flex margin="0 0 6px 0">
            <InputLabel
              dataCy={'input-label'}
              label={props.label}
              name={props.name}
              required={props.viewOnly ? false : props.rules?.required?.value}
              readOnly={props.readOnly}
              rules={props.rules}
              viewOnly={props.viewOnly}
            />
          </ContainerItem>

          {props.tooltip ? (
            <ContainerItem padding={0} align="center" flex margin="-3px 0 0 2px">
              <Tooltip variant="text" title={props.tooltip} placement="right">
                <Icon type="help" color="primary" />
              </Tooltip>
            </ContainerItem>
          ) : null}
        </Container>
      ) : null}

      {props.viewOnly && props.percentage ? (
        <Text
          sx={props.bold ? { fontWeight: 700, fontSize: '18px', lineHeight: 'normal' } : {}}
          content={`${formatToPercentage(props.control?._formValues[props.name], 100)}`}
          dataCy="percent"
        />
      ) : (
        <Controller
          control={props.control}
          name={props.name}
          defaultValue={props.defaultValue}
          rules={props.rules}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <NumberFormat
                  className={'MuiInput-input MuiInputBase-input'}
                  decimalScale={props.isIntegerOnly ? 0 : undefined}
                  decimalSeparator={props.unformatted ? undefined : '.'}
                  onBlur={() => {
                    if (props.onBlur) {
                      props.onBlur();
                    }

                    onBlur();
                  }}
                  onValueChange={(e) => {
                    isAllowed({
                      value: e.value,
                      floatValue: e.floatValue,
                      formattedValue: e.formattedValue
                    });
                    const number = e.floatValue || e.floatValue === 0 ? Number(e.floatValue) : 0;

                    if (props.onChange) {
                      props.onChange(number);
                    }
                    onChange(number);
                  }}
                  value={value == null ? '' : value}
                  thousandSeparator={!props.unformatted}
                  disabled={props.readOnly || props.viewOnly}
                  readOnly={props.readOnly}
                  viewOnly={props.viewOnly}
                  name={props.name}
                  error={error}
                  customInput={props.customInput ? props.customInput : Input}
                  allowNegative={props.allowNegative}
                  isAllowed={isAllowed}
                />
                {!props.onError ? (
                  <Error
                    content={
                      props.viewOnly
                        ? ''
                        : fieldError
                        ? `* ${fieldError.message || errorMessages()[fieldError.type]}`
                        : error
                        ? `* ${props.label} ${error.message || errorMessages()[error.type]}`
                        : ''
                    }
                    dataCy="validation-message"
                  />
                ) : fieldError ? (
                  props.onError(`* ${fieldError.message || errorMessages()[fieldError.type]}`)
                ) : error ? (
                  props.onError(`* ${props.label} ${error.message || errorMessages()[error.type]}`)
                ) : (
                  props.onError('')
                )}
              </>
            );
          }}
        />
      )}
    </FormControl>
  );
};
