import { Grid } from '@mui/material';
import { ContainerSizesProps, useContainerSizes } from 'hooks';
import * as React from 'react';

interface Props extends ContainerSizesProps {
  alignContent?: 'left' | 'center' | 'right';
  dataCy?: string;
  fullHeight?: boolean;
  gutterBottom?: boolean;
  gutterTop?: boolean;
  onClick?: () => void;
  verticalAlign?: 'top' | 'center' | 'bottom';
  children?: React.ReactNode;
}

export const ViewItem: React.FC<Props> = (props) => {
  const sizes = useContainerSizes(props);

  return (
    <Grid
      item
      {...sizes}
      data-cy={props.dataCy}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      sx={{
        alignItems: props.verticalAlign
          ? props.verticalAlign === 'top'
            ? 'flex-start'
            : props.verticalAlign === 'bottom'
            ? 'flex-end'
            : 'center'
          : '',
        display: props.verticalAlign ? 'flex' : '',
        height: props.fullHeight ? '100%' : 'auto',
        justifyContent: props.alignContent ? props.alignContent : '',
        marginTop: props.gutterTop ? '1rem' : '',
        marginBottom: props.gutterBottom ? '1rem' : '',
        textAlign: props.alignContent ? props.alignContent : 'left',
        cursor: props.onClick ? 'pointer' : 'inherit'
      }}
    >
      {props.children}
    </Grid>
  );
};
