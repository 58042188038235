import { Box, Card as CardContainer, CardActions } from '@mui/material';
import { ReportType } from 'api';
import { ReactComponent as AccountIcon } from 'assets/images/reports-icons/ic-report-account.svg';
import { ReactComponent as CommentsIcon } from 'assets/images/reports-icons/ic-report-comments.svg';
import { ReactComponent as DepositsIcon } from 'assets/images/reports-icons/ic-report-deposits.svg';
import { ReactComponent as FinCenIcon } from 'assets/images/reports-icons/ic-report-finCEN.svg';
import { ReactComponent as MJLicenseIcon } from 'assets/images/reports-icons/ic-report-marijuana-license.svg';
import { ReactComponent as QuestionnairesIcon } from 'assets/images/reports-icons/ic-report-questionnaires.svg';
import { ReactComponent as RawDataIcon } from 'assets/images/reports-icons/ic-report-raw-data.svg';
import { ReactComponent as SalesIcon } from 'assets/images/reports-icons/ic-report-sales-activity.svg';
import { ReactComponent as TimeIcon } from 'assets/images/reports-icons/ic-report-time.svg';
import { ReactComponent as TransactionsIcon } from 'assets/images/reports-icons/ic-report-transactions.svg';
import { ReactComponent as UserIcon } from 'assets/images/reports-icons/ic-report-user.svg';
import React from 'react';
import { Button, CardContent, CardHeader, Spinner, ZeroState } from 'ui';

export interface ReportIconProps {
  report: ReportType;
}

const ReportIcon = (props: ReportIconProps): JSX.Element => {
  const report = props.report;

  const lookupReportIcon = (icon: ReportType) => {
    const icons: Record<string, JSX.Element> = {
      account: <AccountIcon />,
      daily: <TimeIcon />,
      deposits: <DepositsIcon />,
      finCENCTR: <FinCenIcon />,
      finCENSAR: <FinCenIcon />,
      license: <MJLicenseIcon />,
      rawSales: <RawDataIcon />,
      sales: <SalesIcon />,
      user: <UserIcon />,
      questionnaires: <QuestionnairesIcon />,
      transaction: <TransactionsIcon />,
      comments: <CommentsIcon />
    };

    return icons[icon];
  };

  return lookupReportIcon(report);
};

interface Props {
  alignContent?: 'left' | 'center' | 'right';
  dataCy?: string;
  header?: {
    align?: 'flex-start' | 'center' | 'flex-end';
    icon?: ReportType;
    justify?: 'left' | 'center' | 'right';
    title?: string;
    subtitle?: string;
    actions?: React.ReactNode;
    type?: 'h1' | 'h2';
  };
  height?: string;
  isLoading?: boolean;
  maxWidth?: string;
  noPadding?: boolean;
  zeroState?: {
    icon?: string;
    title?: string;
    subtitle?: string;
  };
  padding?: string;
  report: Report;
  children?: React.ReactNode;
}

export interface ReportField {
  name: string;
  label?: string;
  radioButtons?: boolean;
}

export interface Report {
  id: string;
  name: string;
  description: string;
  type: ReportType;
  fields: ReportField[];
  callback?: (report: Report) => void;
}

const ButtonContainer: React.FC<Props> = (props) => {
  return (
    <CardActions sx={{ mt: 'auto', padding: '1.5rem 1.5rem 1rem', width: '100%' }}>
      {props.children}
    </CardActions>
  );
};

export const ReportsCard: React.FC<Props> = (props) => {
  const height = props.height ? props.height : 'auto';
  const report = props.report;

  const ReportsCardZeroState = () => (
    <ZeroState
      icon={props.zeroState?.icon}
      title={props.zeroState?.title}
      subTitle={props.zeroState?.subtitle}
    />
  );

  return (
    <CardContainer
      data-cy={props.dataCy}
      sx={{
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        height: height,
        overflow: 'hidden',
        width: '100%',
        '& .MuiCardContent-root:first-of-type': { pt: props.noPadding ? '0' : '1.625rem' },
        '& .MuiCardContent-root:last-child': { pb: props.noPadding ? '0' : '2.5rem' }
      }}
      variant="outlined"
    >
      {props.header?.icon && (
        <Box sx={{ width: '100%', marginTop: '1.5rem', marginLeft: '1.5rem' }}>
          <ReportIcon report={report.type} />
        </Box>
      )}
      {props.header && (props.header.title || props.header.subtitle || props.header.actions) && (
        <CardHeader
          align={props.header.align}
          justify={props.header.justify}
          title={props.header.title ? props.header.title : ''}
          subtitle={props.header.subtitle}
          actions={props.header.actions}
          type={props.header.type}
        />
      )}
      <CardContent
        alignContent={props.alignContent}
        maxWidth={props.maxWidth}
        noPadding={props.noPadding || false}
      >
        {props.isLoading ? <Spinner /> : props.zeroState ? <ReportsCardZeroState /> : props.children}
      </CardContent>
      <ButtonContainer report={report}>
        <Button
          width="100%"
          fullWidth={true}
          color="primary-outlined"
          label="Create Export"
          onClick={() => {
            if (report.callback) {
              report.callback(report);
            }
          }}
        />
      </ButtonContainer>
    </CardContainer>
  );
};
