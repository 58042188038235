import {
  BusinessLicenseDocument,
  CrbServiceProvider,
  CreateDocumentsRequest,
  DocumentUpload,
  DocumentUploadRoot,
  DocumentUploadSymlink,
  DueDiligenceDocument,
  DueDiligenceDocumentStatus,
  DueDiligenceRequirement,
  DueDiligenceRequirementStatus,
  DueDiligenceStatus,
  IANATimezones,
  MarketplaceOfferCategory,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult,
  RequirementType,
  TemplateResponse,
  TemplateResultResponse,
  User
} from '@gcv/shared';
import { MyProvidersStatus } from 'domain/enums';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { CommentStore } from 'stores/CommentStore';
import { ComplianceStore } from 'stores/ComplianceStore';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { Row } from 'ui';
import { DateTimeHelpers } from 'util/dateTime.util';
import {
  DueDiligenceRequirementTemplateWithType,
  getOnboardingRequirementsForCrb
} from 'util/due-diligence.util';
import { getObjectMap } from 'util/objectUtils';
import { MyProvidersRepo } from '../../my-providers.repo';
import { PDPM, ProviderDetailsRepo } from './provider-details.repo';
import { createPlaceholderRequirementData } from 'util/due-diligence.util';
import { viewDocument } from 'util/s3.util';
import { getMimeType, openFileInNewWindow } from 'util/files.util';

export interface DocumentTableData {
  requirement: string;
  number_of_documents: number;
  type: string;
  last_updated: string;
}
interface FileMap {
  [fieldId: string]: string[];
}
interface NewFilesMap {
  [fieldId: string]: {
    requirementId: string;
    files: File[];
  };
}
export interface PDVM {
  loading: boolean;
  loadingAdditionalInfo: boolean;
  loadingRequirement: boolean;
  template: TemplateResponse | null;
  templateResult: TemplateResultResponse | null;
  details: CrbServiceProvider | null;
  existingFilesIdsMap: FileMap;
  allDocumentsMap: { [id: string]: DocumentUpload };
  documentRows: Row<DocumentTableData>[];
  additionalInfoEditMode: boolean;
  logoUrl?: string;
  dueDiligenceStatus: MyProvidersStatus;
  providerCategory: ReadableCategory;
  ddSubmittedBy: string;
  ddApprovedDate: string;
  ddLastUpdated: string;
  docRequirement: DueDiligenceRequirementTemplateWithType | null;
  docRequirementResult: DueDiligenceRequirement | OnboardingDocumentRequirementResult;
  isBusinessLicense: boolean;
  currentDocument?: DocumentUpload;
  documentUser?: User;
  currentDocumentMetadata?: DueDiligenceDocument;
  license?: BusinessLicenseDocument;
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  dispensaryId: string;
}

export enum ReadableCategory {
  banking = 'Banking',
  lending = 'Lending',
  insurance = 'Insurance',
  professional_services = 'Professional Services',
  payments = 'Payments',
  logistics = 'Logistics',
  hr_payroll = 'HR Payroll',
  other = 'Other'
}

@injectable()
export class ProviderDetailsPresenter {
  @inject(MyProvidersRepo)
  private parentRepo: MyProvidersRepo;

  @inject(ProviderDetailsRepo)
  private repo: ProviderDetailsRepo;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  @inject(ComplianceStore)
  private complianceStore: ComplianceStore;

  @inject(CrbDispensaryStore)
  private crbDispensaryStore: CrbDispensaryStore;

  @inject(CommentStore)
  private commentStore: CommentStore;

  constructor() {
    makeAutoObservable(this);
  }

  public newFilesMap: NewFilesMap = {};

  public viewModel: PDVM = {
    loading: true,
    loadingAdditionalInfo: true,
    loadingRequirement: true,
    template: null,
    templateResult: null,
    details: null,
    existingFilesIdsMap: {},
    allDocumentsMap: {},
    documentRows: [],
    docRequirement: null,
    additionalInfoEditMode: false,
    dueDiligenceStatus: MyProvidersStatus.NotStarted,
    providerCategory: ReadableCategory.banking,
    ddSubmittedBy: '--',
    ddApprovedDate: '--',
    ddLastUpdated: '--',
    isBusinessLicense: false,
    docRequirementResult: {
      id: '',
      do_not_have: false,
      do_not_have_comments: '',
      documents: {},
      last_updated: '',
      name: '',
      reviewed_by: '',
      review_date: '',
      status: DueDiligenceRequirementStatus.NEEDS_REVIEW,
      weeks_before_expiration_reminder: 0
    },
    sharedRootDocs: [],
    dispensaryId: ''
  };

  private updateViewModel = action((viewModel: Partial<PDVM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  private loadStore = action(async (providerId: string, details: CrbServiceProvider | null) => {
    try {
      await this.repo.load(providerId, details);
    } catch (e) {
      const error = e as Error;
      this.snackbarStore.showErrorSnackbarMessage(
        "There was an error loading the provider's details. Please ensure you are logged into the correct org or contact support for additional help."
      );
    }
  });

  public load = action(async (providerId: string) => {
    this.updateViewModel({
      loading: true,
      loadingAdditionalInfo: true,
      loadingRequirement: true,
      dispensaryId: this.crbDispensaryStore.currentDispensary.id
    });

    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PDPM;
      this.updateViewModel({
        template: programmersModel.template,
        templateResult: programmersModel.templateResult,
        details: programmersModel.details,
        logoUrl:
          programmersModel.details?.templates.value.onboarding.marketplace_offer_snapshot?.image_url ??
          details?.theme?.logo_s3_key,
        dueDiligenceStatus: this.convertDueDiligenceStatus(
          programmersModel.templateResult?.status as DueDiligenceStatus
        ),
        providerCategory:
          ReadableCategory[
            (programmersModel.details?.templates.value.onboarding.marketplace_offer_snapshot
              ?.offer_category as MarketplaceOfferCategory) || (MarketplaceOfferCategory.Banking as 'banking')
          ],
        ddSubmittedBy: programmersModel.templateResult?.submitted_by
          ? `${
              this.crbDispensaryStore.currentDispensaryStaff.find(
                (u) => u.id === programmersModel.templateResult?.submitted_by
              )?.firstName
            } ${
              this.crbDispensaryStore.currentDispensaryStaff.find(
                (u) => u.id === programmersModel.templateResult?.submitted_by
              )?.lastName
            }`
          : '--',
        ddApprovedDate: programmersModel.templateResult?.reviewed_date
          ? DateTimeHelpers.formatISOToTableDateString(
              programmersModel.templateResult?.reviewed_date,
              this.crbDispensaryStore.currentDispensary.iana_timezone
            )
          : '--',
        ddLastUpdated: programmersModel.details?.templates.value.onboarding.last_updated
          ? DateTimeHelpers.formatISOToTableDateString(
              programmersModel.details?.templates.value.onboarding.last_updated,
              this.crbDispensaryStore.currentDispensary.iana_timezone
            )
          : '--'
      });
      if (this.viewModel.template && this.viewModel.templateResult) {
        this.makeDocumentRows(this.viewModel.template, this.viewModel.templateResult);
      }
    });

    let details: CrbServiceProvider | null = null;
    if (this.parentRepo.programmersModel.providers) {
      details = this.parentRepo.programmersModel.providers[providerId];
    }
    await this.loadStore(providerId, details);

    this.updateViewModel({
      loading: false,
      loadingAdditionalInfo: false,
      loadingRequirement: false,
      sharedRootDocs: this.repo.programmersModel.sharedRootDocs
    });
  });

  public updateFormWithResult = async (form: UseFormReturn<FieldValues, any>) => {
    if (
      this.viewModel.templateResult &&
      this.viewModel.templateResult.custom_requirement_result &&
      this.viewModel.templateResult.custom_requirement_result.length > 0
    ) {
      this.updateViewModel({ loading: true, loadingAdditionalInfo: true });

      const updatedValues = {} as FieldValues;
      for (let index = 0; index < this.viewModel.templateResult.custom_requirement_result.length; index++) {
        const customResult = this.viewModel.templateResult.custom_requirement_result[index];

        if (
          customResult.custom_fields &&
          customResult.custom_fields.responses &&
          Object.keys(customResult.custom_fields.responses).length > 0
        ) {
          Object.entries(customResult.custom_fields.responses).forEach((entry) => {
            updatedValues[entry[0]] = entry[1];
          });
        }
      }
      form.reset(updatedValues);
      this.updateViewModel({ loading: false, loadingAdditionalInfo: false });
    }
  };

  public fileUpload = async (requirementId: string, fieldId: string, file: File) => {
    const doc = await this.repo.uploadOnboardingTemplateDocument(requirementId, fieldId, file);
    if (doc) {
      const updatedDocMap = {
        ...this.viewModel.allDocumentsMap
      };
      updatedDocMap[doc.id] = doc;
      this.updateViewModel({ allDocumentsMap: updatedDocMap });
    }
    return doc;
  };

  public getUploadDocIdsMap = async () => {
    const idsMap: FileMap = {};
    const promises = [];

    const addFileToMap = async (fieldId: string, file: File, map: FileMap, requirementId: string) => {
      const doc = await this.fileUpload(requirementId, fieldId, file);
      if (!map[fieldId]) {
        map[fieldId] = [doc ? doc.id : ''];
      } else {
        map[fieldId].push(doc ? doc.id : '');
      }
    };

    for (const fieldId in this.newFilesMap) {
      for (const file of this.newFilesMap[fieldId].files) {
        promises.push(addFileToMap(fieldId, file, idsMap, this.newFilesMap[fieldId].requirementId));
      }
    }

    await Promise.all(promises);
    return idsMap;
  };

  public submitForm = async (data: FieldValues, form: UseFormReturn<FieldValues, any>) => {
    this.updateViewModel({ loading: true, additionalInfoEditMode: false });
    const uploadedDocIdsMap = await this.getUploadDocIdsMap();

    Object.keys(uploadedDocIdsMap).map((fieldId) => {
      if (this.viewModel.existingFilesIdsMap[fieldId]) {
        data[fieldId] = [...this.viewModel.existingFilesIdsMap[fieldId], ...uploadedDocIdsMap[fieldId]];
      } else {
        data[fieldId] = [...uploadedDocIdsMap[fieldId]];
      }
    });
    try {
      await this.repo.saveAndCompleteOnboardingTemplate(data);
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an error saving the form data. Please contact support for additional help.'
      );
      console.log('error saving onboarding template', e);
    } finally {
      this.snackbarStore.showSuccessSnackbarMessage('Additional info has been updated successfully.');
    }

    this.newFilesMap = {};
    this.updateViewModel({ loading: false });
  };

  public editForm = (editFormOn: boolean) => {
    this.updateViewModel({ additionalInfoEditMode: editFormOn });
  };

  public deleteOnboardingTemplateDocument = async (
    requirementId: string,
    fieldId: string,
    file: DocumentUpload,
    form: UseFormReturn<FieldValues, any>,
    existingFilesIdsMap: FileMap
  ) => {
    this.updateViewModel({ loadingAdditionalInfo: true });

    if (this.viewModel.template) {
      await this.repo.deleteOnboardingTemplateDocument(file);

      const updatedDocMap = {
        ...this.viewModel.allDocumentsMap
      };
      delete updatedDocMap[file.id];
      this.updateViewModel({ allDocumentsMap: updatedDocMap });
      const updatedFiles = existingFilesIdsMap[fieldId].filter((id) => id !== file.id);
      existingFilesIdsMap[fieldId] = updatedFiles;
      this.updateViewModel({ existingFilesIdsMap: existingFilesIdsMap });
      form.setValue(fieldId, updatedFiles);
    }
    this.updateViewModel({ loadingAdditionalInfo: false });
  };

  private formatText = (text: string) => {
    if (!text) {
      return '';
    }

    // remove underscores
    text = text.replace('_', ' ');

    // capitalize letters
    text = text.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });

    return text;
  };

  public makeDocumentRows = action(
    async (template: TemplateResponse, templateResult: TemplateResultResponse) => {
      const requirementsMetadataMap = getObjectMap(templateResult.requirements_results, 'requirement_id');
      const dueDiligenceTemplate = getOnboardingRequirementsForCrb(
        template,
        this.complianceStore.licenseDataMap['CA']?.employee_license_required
      );
      const rows = Object.keys(dueDiligenceTemplate).map((reqId) => {
        const lastUpdated = requirementsMetadataMap[reqId]?.date_updated;
        const type = dueDiligenceTemplate[reqId].category;

        return {
          id: reqId,
          data: {
            requirement: this.formatText(dueDiligenceTemplate[reqId].name),
            number_of_documents: requirementsMetadataMap[reqId]
              ? Object.values(requirementsMetadataMap[reqId].documents).length
              : 0,
            type: this.formatText(type),
            last_updated: lastUpdated
              ? DateTimeHelpers.formatISOToTableDateString(
                  lastUpdated,
                  this.viewModel.details?.iana_timezone || IANATimezones.America_NewYork
                )
              : ''
          }
        };
      });
      this.updateViewModel({ documentRows: rows });
    }
  );

  public getDocuments = action(async () => {
    this.updateViewModel({ loadingRequirement: true });
    const documentsMap = await this.repo.getDocuments(this.crbDispensaryStore.currentDispensary.id);
    this.updateViewModel({ allDocumentsMap: documentsMap, loadingRequirement: false });
  });

  public addDocumentToOnboardingDocumentRequirement = action(
    async (
      requirementId: string | undefined,
      documents: CreateDocumentsRequest,
      docSharingFeatureFlag: boolean
    ) => {
      this.updateViewModel({ loadingRequirement: true });
      if (requirementId) {
        const templateReqResponse = await this.repo.addDocToOnboarding(
          requirementId,
          documents,
          docSharingFeatureFlag
        );
        if (templateReqResponse) {
          const templateResponse: TemplateResultResponse = {
            ...(this.viewModel.templateResult as TemplateResultResponse)
          };
          const indx = templateResponse.requirements_results.findIndex((requirement) => {
            return requirement.requirement_id === requirementId;
          });
          if (indx > -1) {
            templateResponse.requirements_results[indx] = templateReqResponse;
          } else {
            templateResponse.requirements_results?.push(templateReqResponse);
          }
          this.updateViewModel({ templateResult: templateResponse });
        }
        await this.getDocuments();
      }
      this.updateViewModel({ loadingRequirement: false });
    }
  );

  public setRequirement = action(async (requirementId: string) => {
    const currentRequirement = this.viewModel.template?.requirements[requirementId];
    if (currentRequirement) {
      this.updateViewModel({
        docRequirement: currentRequirement,
        isBusinessLicense: currentRequirement.type === RequirementType.BusinessLicense
      });
    }
    this.updateViewModel({
      loadingRequirement: false,
      loading: false
    });
  });

  public setRequirementResult = action((requirementId: string) => {
    if (this.viewModel.docRequirement) {
      const requirementData =
        this.viewModel.templateResult?.requirements_results.find((r) => r.requirement_id === requirementId) ??
        createPlaceholderRequirementData(this.viewModel.docRequirement);

      requirementData.documents = Object.values(requirementData.documents).reduce((acc, curr) => {
        acc = { ...acc, [curr.id]: curr };

        return acc;
      }, {});
      this.updateViewModel({ docRequirementResult: requirementData });
    }
  });

  public convertDueDiligenceStatus = (status: DueDiligenceStatus): MyProvidersStatus => {
    switch (status) {
      case DueDiligenceStatus.BANK_APPROVED:
        return MyProvidersStatus.Active;
      case DueDiligenceStatus.BANK_AWAITING_REVIEW:
        return MyProvidersStatus.Submitted;
      case DueDiligenceStatus.BANK_DISCONNECTED:
        return MyProvidersStatus.Inactive;
      case DueDiligenceStatus.BANK_IN_PROGRESS:
        return MyProvidersStatus.Started;
      case DueDiligenceStatus.BANK_PENDING:
        return MyProvidersStatus.Started;
      case DueDiligenceStatus.BANK_REVIEW_IN_PROGRESS:
        return MyProvidersStatus.UnderReview;
      case DueDiligenceStatus.GCV_APPROVED:
        return MyProvidersStatus.Active;
      case DueDiligenceStatus.GCV_AWAITING_REVIEW:
        return MyProvidersStatus.NotStarted;
      case DueDiligenceStatus.GCV_IN_PROGRESS:
        return MyProvidersStatus.Started;
      case DueDiligenceStatus.GCV_PENDING:
        return MyProvidersStatus.NotStarted;
      case DueDiligenceStatus.UPDATE_REQUIRED:
        return MyProvidersStatus.UnderReview;
      default:
        return MyProvidersStatus.NotStarted;
    }
  };

  public openComments = () => {
    this.commentStore.openComments(true);
  };

  private getRootDocId = (linked_doc_id: string): string => {
    const linkedDocArray = linked_doc_id.split('_');
    return linkedDocArray[1];
  };

  public viewDocumentDetails = (docId: string) => {
    let doc = this.viewModel.allDocumentsMap[docId];
    const docMetaData = this.viewModel.docRequirementResult.documents[doc.id];
    const user = this.crbDispensaryStore.currentDispensaryStaff.find((u) => u.id === doc.uploaded_by);
    if (!user && (doc as DocumentUploadSymlink).linked_doc_id) {
      const rootDoc =
        this.viewModel.allDocumentsMap[this.getRootDocId((doc as DocumentUploadSymlink).linked_doc_id)];
      if ((rootDoc as DocumentUploadRoot)?.third_party_upload_org_id) {
        doc = rootDoc;
      }
    }
    if (this.viewModel.docRequirement?.type === RequirementType.BusinessLicense) {
      if ((this.viewModel.allDocumentsMap[docId] as DocumentUploadSymlink).linked_doc_id) {
        for (const srd of this.viewModel.sharedRootDocs) {
          if (srd.requirement.type === RequirementType.BusinessLicense) {
            for (const dd of srd.ddDocuments) {
              if (
                dd.id ===
                this.getRootDocId(
                  (this.viewModel.allDocumentsMap[docId] as DocumentUploadSymlink).linked_doc_id
                )
              ) {
                this.updateViewModel({
                  license: dd as BusinessLicenseDocument
                });
              }
            }
          }
        }
      } else {
        this.updateViewModel({
          license: this.viewModel.docRequirementResult.documents[docId] as BusinessLicenseDocument
        });
      }
    }

    this.updateViewModel({
      currentDocumentMetadata: docMetaData,
      currentDocument: doc,
      documentUser: user
    });
    return { docMetaData, doc };
  };

  public openDocument = async (docId: string, file_name: string) => {
    const blob = await viewDocument(
      { orgId: this.crbDispensaryStore.currentDispensary.id, userType: 'dispensary' },
      docId
    );

    if (blob) {
      openFileInNewWindow(blob, getMimeType(file_name), file_name);
    }
  };
}
