import { FormControl, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { Control, Controller, FieldValues, Validate } from 'react-hook-form';
import { Container, ContainerItem, Error, Icon, InputLabel, RadioButton, Text, Tooltip } from 'ui';
import { errorMessages } from 'util/forms.util';
import { ReactComponent as HelpIcon } from 'assets/images/ic-help.svg';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface RadioButtonProps {
  control?: Control;
  dataCy?: string;
  label: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  name: string;
  justifyContent?: 'row' | 'column';
  onBlur?: () => void;
  onChange?: (value: string | boolean) => void;
  options: { label: string; value: string | boolean; checked?: boolean; readOnly?: boolean }[];
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  tooltip?: string;
  customErrorMessageForError?: boolean;
  optionMinWidth?: string;
  optionMaxWidth?: string;
  inlineLabel?: boolean;
  hideLabel?: boolean;
}

/**
 * Molecule Component for a grouping of Radio Button options
 *
 * Note: If you are using options with boolean values, react will determine false to be an invalid option if this field is marked required
 * Instead, to make this field required and valid for any boolean option, use a custom validate rule:
 * @example rules={{ validate: (val) => val !== undefined }} //requires that any value is selected, as long as one is selected
 */
export const InputRadio: React.FC<RadioButtonProps> = (props) => {
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end';
  const justifyContent = props.justifyContent ? props.justifyContent : 'column';
  return (
    <FormControl component="fieldset" data-cy={props.dataCy}>
      {/* custom label added for the case when tooltip and label are not inlined.
      Pass inline label as true to make the tooltip & label inline */}

      {props.inlineLabel ? (
        <Box sx={{ display: 'inline', width: '95%' }}>
          <Text
            inline
            sx={{ fontWeight: '700', fontSize: '13px', lineHeight: '15px' }}
            content={props.label}
          />
          {props.tooltip ? (
            <Tooltip
              ellipses
              variant="text"
              title={props.tooltip}
              placement="right"
              dataCy="radio-group-tooltip"
            >
              <HelpIcon
                style={{
                  marginLeft: '4px'
                }}
              />
            </Tooltip>
          ) : null}
        </Box>
      ) : (
        <Container
          padding={props.justifyContent && props.justifyContent === 'row' ? '0 0 3px 0' : '0'}
          align="center"
        >
          <ContainerItem padding="0 0 6px 0" align="center" flex>
            <InputLabel
              dataCy={'radio-group-label'}
              label={props.label}
              name={props.name}
              required={
                props.readOnly || props.viewOnly || props.hideLabel ? false : props.rules?.required?.value
              }
            />
          </ContainerItem>
          {props.tooltip ? (
            <ContainerItem padding={0} align="center" flex>
              <Tooltip
                ellipses
                variant="text"
                title={props.tooltip}
                placement="right"
                dataCy="radio-group-tooltip"
              >
                <Icon type="help" color="primary" />
              </Tooltip>
            </ContainerItem>
          ) : null}
        </Container>
      )}

      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <RadioGroup
            row
            data-cy={'radio-group-options'}
            onBlur={() => {
              if (props.onBlur) {
                props.onBlur();
              }

              onBlur();
            }}
            onChange={(event) => {
              const selectedValue = props.options.find(
                (o) => o.value.toString() === event.target.value
              )?.value;
              if (selectedValue !== undefined) {
                onChange(selectedValue);
                if (props.onChange) props.onChange(selectedValue);
              }
            }}
          >
            <Container column={justifyContent === 'column'} padding={0} width="100%">
              {props.options.map((o) => {
                return (
                  <ContainerItem
                    key={o.label + o.value}
                    padding={'0 0 0.5rem 0'}
                    width={justifyContent === 'column' ? '100%' : ''}
                    minWidth={props.optionMinWidth}
                    maxWidth={props.optionMinWidth}
                    dataCy={`radio-group-option-${o.value}`}
                  >
                    <Box
                      sx={{
                        '& .MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.Mui-disabled.Mui-checked':
                          {
                            color: props.viewOnly ? '#03A65B' : 'unset'
                          },
                        '& .Mui-checked~.MuiTypography-root.MuiFormControlLabel-label.Mui-disabled': {
                          color: 'black'
                        },
                        '& .Mui-disabled': { cursor: 'not-allowed' },
                        overflowWrap: 'anywhere'
                      }}
                    >
                      <RadioButton
                        key={o.label}
                        checked={o.checked || o.value === value}
                        label={o.label}
                        labelPlacement={labelPlacement}
                        readOnly={o.readOnly || props.readOnly || props.viewOnly}
                        value={o.value}
                        dataCy="radio-group-option-button"
                      />
                    </Box>
                  </ContainerItem>
                );
              })}

              <ContainerItem padding={0} width="100%">
                <Error
                  content={
                    error
                      ? props.customErrorMessageForError
                        ? `* ${error.message || errorMessages()[error.type]}`
                        : `* ${props.label} ${error.message || errorMessages()[error.type]}`
                      : ''
                  }
                />
              </ContainerItem>
            </Container>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
