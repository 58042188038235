import {
  AccountInternalMap,
  AccountReviewType,
  BankDispensaryMetadataData,
  DispensaryAccountReviewWithData,
  DispensaryAccountReviewWithDataV2,
  DueDiligenceStatus,
  MUO,
  OrganizationType,
  ReviewTargets
} from '@gcv/shared';
import { friendlyAccountTypes } from 'domain/consts/account-types';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import {
  MUODispensaryAccountReviewWithData,
  MUODispensaryAccountReviewWithDataV2
} from 'ui/apps/fi/monitoring/pages/review/model';
import { FilterListChild } from '../FilterDropdown/filter-dropdown.organism';
import { FilterSelectOption } from '../FilterSelect/filter-select.organism.model';

export const ACCOUNT_NUMBERS_LABEL = 'account_numbers';
export const SALES_LOCATION_LABEL = 'sales_location';

const getAccountNumberOptionsForReview = (accountNumbers: string[], metadata: BankDispensaryMetadataData) => {
  const option = accountNumbers.map((an) => {
    const account = metadata.accounts?.[an];
    let label = `*${an.slice(-4)}`;
    if (account) {
      const nickname = account?.name ? account.name : account.type ? friendlyAccountTypes[account.type] : '';
      if (nickname) {
        label = `${nickname} ${label}`;
      }
    }
    const childOption: FilterListChild = {
      label,
      value: an,
      selected: true
    };
    return childOption;
  });
  return option;
};

const getAccountNumberOptionsForReviewSchedule = (accounts: AccountInternalMap, selected?: string[]) => {
  const option = Object.entries(accounts).map(([an, account]) => {
    let label = `*${an.slice(-4)}`;
    if (account) {
      const nickname = account?.name ? account.name : account.type ? friendlyAccountTypes[account.type] : '';
      if (nickname) {
        label = `${nickname} ${label}`;
      }
    }
    const childOption: FilterListChild = {
      label,
      value: an,
      selected: selected?.includes(an)
    };
    return childOption;
  });
  return option;
};

export const getFilterOptionsForReview = (
  review: MUODispensaryAccountReviewWithData | DispensaryAccountReviewWithData,
  childReviews: DispensaryAccountReviewWithDataV2[]
): FilterSelectOption[] => {
  let filterDropDownOptions: FilterSelectOption[] = [];
  const disp = getFiDispensaryStore().findDispensary(review.dispensary_id);

  if (review.type === AccountReviewType.MUO) {
    const muo = disp as MUO;
    const muoReview = review as unknown as MUODispensaryAccountReviewWithDataV2;
    if (muo.shared_internal_id) {
      const accountNumbersOption: FilterSelectOption = {
        label: 'Account Numbers',
        value: ACCOUNT_NUMBERS_LABEL,
        selected: true,
        children: getAccountNumberOptionsForReview(
          childReviews[0].account_numbers,
          review.dispensary_metadata.value
        )
      };
      const salesLocationOption: FilterSelectOption = {
        label: 'Sales Location',
        value: SALES_LOCATION_LABEL,
        selected: true,
        children: Object.keys(muoReview.targets).map((crbId) => {
          const matchingReview = childReviews.find(
            (r) => r.dispensary_id === crbId
          ) as DispensaryAccountReviewWithDataV2;
          const option: FilterSelectOption = {
            label: matchingReview!.dispensary_name,
            value: crbId,
            selected: true
          };
          return option;
        })
      };
      filterDropDownOptions = [accountNumbersOption, salesLocationOption];
    } else {
      filterDropDownOptions = Object.keys(muoReview.targets).map((crbId) => {
        const matchingReview = childReviews.find(
          (r) => r.dispensary_id === crbId
        ) as DispensaryAccountReviewWithDataV2;
        const option: FilterSelectOption = {
          label: matchingReview.dispensary_name,
          value: crbId,
          selected: true,
          children: getAccountNumberOptionsForReview(
            matchingReview.account_numbers,
            matchingReview.dispensary_metadata.value
          )
        };
        return option;
      });
    }
  } else {
    const reviewV2 = review as unknown as DispensaryAccountReviewWithDataV2;
    const option: FilterSelectOption = {
      label: review.dispensary_name,
      value: review.dispensary_id,
      selected: true,
      children: getAccountNumberOptionsForReview(reviewV2.account_numbers, reviewV2.dispensary_metadata.value)
    };
    return [option];
  }

  return filterDropDownOptions;
};

export const getReviewTargetsFromSelection = (
  filterSelections: FilterSelectOption[],
  rawOptions: FilterSelectOption[]
): ReviewTargets => {
  const accountNumbersOption = filterSelections.find((o) => o.value === ACCOUNT_NUMBERS_LABEL);
  const salesLocationsOption = filterSelections.find((o) => o.value === SALES_LOCATION_LABEL);

  if (accountNumbersOption || salesLocationsOption) {
    const allLocations = salesLocationsOption?.children?.length
      ? salesLocationsOption.children
      : rawOptions.find((o) => o.value === SALES_LOCATION_LABEL)?.children ?? [];
    const allAccountNumbers = accountNumbersOption?.children ?? [];
    const targets: ReviewTargets = allLocations.reduce((targets, location) => {
      targets[location.value] = { account_numbers: allAccountNumbers.map((c) => c.value) };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  } else if (filterSelections.every((op) => !op.children)) {
    const targets: ReviewTargets = filterSelections.reduce((targets, parent) => {
      const accountNumbers = parent.children ?? [];
      const account_numbers = accountNumbers.map((c) => c.value);
      targets[parent.value] = { account_numbers };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  } else {
    const targets: ReviewTargets = filterSelections.reduce((targets, parent) => {
      const accountNumbers = parent.children ?? [];
      const account_numbers = accountNumbers.map((c) => c.value);
      targets[parent.value] = { account_numbers };
      return targets;
    }, {} as ReviewTargets);
    return targets;
  }
};

export const getFilterOptionsForCrbAutogenReviews = (
  crbId: string,
  reviewTargets?: ReviewTargets
): FilterSelectOption[] => {
  let filterDropDownOptions: FilterSelectOption[] = [];
  const disp = getFiDispensaryStore().findDispensary(crbId);

  const accounts = disp?.internal_metadata?.accounts ?? {};
  if (disp?.orgType === OrganizationType.DISPENSARY) {
    const selectAccounts = reviewTargets ? reviewTargets[disp.id]?.account_numbers : Object.keys(accounts);
    const option: FilterSelectOption = {
      label: disp.name,
      value: disp.id,
      selected: true,
      children: getAccountNumberOptionsForReviewSchedule(accounts, selectAccounts)
    };
    return [option];
  } else if (disp?.orgType === OrganizationType.MUO) {
    const children = getFiDispensaryStore().dispensaries.filter(
      (d) =>
        d.muo_id === disp.id && d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED
    );

    if (disp.shared_internal_id) {
      const selectAccounts = reviewTargets ? reviewTargets[disp.id]?.account_numbers : Object.keys(accounts);
      const accountsChildren = getAccountNumberOptionsForReviewSchedule(accounts, selectAccounts);
      const accountNumbersOption: FilterSelectOption = {
        label: 'Account Numbers',
        value: ACCOUNT_NUMBERS_LABEL,
        selected: accountsChildren.filter((c) => c.selected).length > 0,
        children: accountsChildren
      };
      const salesChildren = [disp, ...children].map((crb) => {
        const option: FilterSelectOption = {
          label: crb.name,
          value: crb.id,
          selected: crb.id === disp.id || reviewTargets === undefined || reviewTargets?.[crb.id] != null,
          disabled: crb.id === disp.id
        };
        return option;
      });
      const salesLocationOption: FilterSelectOption = {
        label: 'Sales Location',
        value: SALES_LOCATION_LABEL,
        selected: salesChildren.filter((c) => c.selected).length > 0,
        children: salesChildren
      };
      filterDropDownOptions = [accountNumbersOption, salesLocationOption];
    } else {
      filterDropDownOptions = [disp, ...children].map((crb) => {
        const accounts = crb?.internal_metadata?.accounts ?? {};
        const selectAccounts = reviewTargets ? reviewTargets[crb.id]?.account_numbers : Object.keys(accounts);
        const option: FilterSelectOption = {
          label: crb.name,
          value: crb.id,
          selected: reviewTargets === undefined || reviewTargets?.[crb.id] != null,
          children: getAccountNumberOptionsForReviewSchedule(accounts, selectAccounts)
        };
        return option;
      });
    }
  }
  return filterDropDownOptions;
};

export const getReviewTargetsForCrb = (crbId: string): ReviewTargets => {
  const disp = getFiDispensaryStore().findDispensary(crbId);
  const accounts = disp?.internal_metadata?.accounts ?? {};
  const targets: ReviewTargets = { [crbId]: { account_numbers: Object.keys(accounts) } };

  if (disp?.orgType === OrganizationType.MUO) {
    const children = getFiDispensaryStore().dispensaries.filter(
      (d) =>
        d.muo_id === disp.id && d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED
    );

    for (const child of children) {
      if (disp.shared_internal_id) {
        targets[child.id] = { account_numbers: Object.keys(accounts) };
      } else {
        targets[child.id] = { account_numbers: Object.keys(child.internal_metadata?.accounts ?? {}) };
      }
    }
  }
  return targets;
};
