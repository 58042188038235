import { Autocomplete, FormControl, TextField, Box, Paper, Icon } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldValues, Validate } from 'react-hook-form';
import { Error, InputLabel } from 'ui';
import { errorMessages } from 'util/forms.util';

interface ValidationRule<T> {
  message: string;
  value: T;
}

export type Option = { value: boolean | number | string; label: string };

/**
 * Compose default values from the props.
 *
 * Note: This function is abstracted out to make it easier to test.
 *
 * @param props a subset of the InputSelectSearchProps interface.
 * @returns a value appropriate for the (internal) Autocomplete component's defaultValue property.
 */
export const composeDefaultValues = (props: {
  options: Option[];
  multiSelect?: boolean;
  defaultValues?: string[];
}): Option[] | Option | null => {
  // The Autocomplete component's property expects these defaults: props.multiple ? [] : null
  let defaultValues: Option[] | Option | null;

  if (!props.defaultValues) {
    defaultValues = props.multiSelect ? [] : null;
  } else {
    const values = [] as Option[];

    props.defaultValues.forEach((v) => {
      const option = props.options.find((o) => o.value === v);
      if (option) {
        values.push(option);
      }
    });

    // This handles the correct defaults for the component.
    if (props.multiSelect) {
      defaultValues = values;
    } else {
      defaultValues = !!values.length ? values[0] : null;
    }
  }

  return defaultValues;
};

interface InputSelectSearchProps {
  label: string;
  name: string;
  options: Option[];
  background?: 'white' | 'gray';
  defaultValues?: string[];
  control?: Control;
  multiSelect?: boolean;
  onChange?: (data: Option | Option[] | null) => void;
  readOnly?: boolean;
  viewOnly?: boolean;
  placeholder?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  dataCy?: string;
  noLabel?: boolean;
  resetOnClear?: boolean;
}

export const InputSelectSearch = (props: InputSelectSearchProps) => {
  const defaultValues = composeDefaultValues(props);

  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      {!props.noLabel ? (
        <Box margin="0 0 6px 0">
          <InputLabel
            dataCy={'select-search-label'}
            label={props.label}
            name={props.name}
            required={props.viewOnly ? false : props.rules?.required?.value}
          />
        </Box>
      ) : null}
      <Controller
        control={props.control}
        name={props.name}
        rules={props.rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Autocomplete
              popupIcon={<Icon>expand_more</Icon>}
              PaperComponent={({ children }) => (
                <Paper
                  style={{
                    boxShadow:
                      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
                  }}
                >
                  {children}
                </Paper>
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={(option as Option).value.toString()}>
                    {(option as Option).label}
                  </li>
                );
              }}
              multiple={props.multiSelect}
              onChange={(e, response) => {
                const option = response as Option;

                if (option) {
                  if (props.onChange) {
                    props.onChange(option);
                  }

                  if (props.multiSelect) {
                    onChange(option);
                  } else {
                    onChange(option.value);
                  }
                } else if (props.resetOnClear) {
                  if (props.onChange) {
                    props.onChange(response as Option);
                  }
                  onChange(option);
                }
              }}
              disabled={props.readOnly}
              defaultValue={
                props.defaultValues ? defaultValues : props.options.find((o) => o.value === value)
              }
              options={props.options}
              sx={{
                backgroundColor: (theme) =>
                  props.background ? props.background : theme.palette.background.default,
                borderColor: (theme) =>
                  props.background === 'gray' ? theme.palette.grey[500] : theme.palette.grey[400],
                borderRadius: '5px',
                '&:focus-within': {
                  boxShadow: `0px 0px 0px 3px rgba(3, 166, 91, 0.25)`
                },
                '.MuiOutlinedInput-root': {
                  paddingRight: '1rem',

                  '& input:disabled': {
                    color: (theme) => theme.palette.text.primary
                  },

                  '&:hover': {
                    outline: 'none',
                    border: 'none',
                    cursor: props.readOnly ? 'not-allowed' : 'default'
                  }
                },
                '.MuiInputBase-input': {
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  '&:hover': {
                    outline: 'none',
                    border: 'none'
                  }
                },
                '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: 0
                },
                '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                  right: '6px'
                }
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    placeholder={!value ? props.placeholder : ''}
                    id={props.name}
                    autoComplete="none"
                    value={value === '' && props.readOnly ? '--' : value}
                    disabled={props.readOnly}
                    InputProps={{ ...params.InputProps }}
                  />
                  <Error
                    content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
                  />
                </>
              )}
            />
          );
        }}
      />
    </FormControl>
  );
};
