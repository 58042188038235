import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { Box, Tab, Tabs } from '@mui/material';
import { ReactComponent as CheckCircleFilled } from 'assets/images/check-circle-filled-icon.svg';
import { ReactComponent as CheckCircle } from 'assets/images/check-circle-light-icon.svg';
import { Container, ContainerItem, ScrollTab, StepperStep, TabLabel } from 'ui';
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link';

/* Private component rendering tabs */

interface PrivateProps {
  containerId: string;
  tabs: ScrollTab[];
}

export const SquareTabs: React.FC<PrivateProps> = (props) => {
  return (
    <Tabs
      sx={{ width: '100%' }}
      variant="fullWidth"
      TabIndicatorProps={{
        style: {
          display: 'none'
        }
      }}
      value={0}
    >
      {props.tabs.map((t, i) => {
        return (
          <Tab
            disableRipple
            key={t.id}
            data-cy={`${t.id}-tab`}
            label={
              <TabLabel
                icon={
                  t.isComplete ? (
                    <CheckCircleFilled height="20px" width="20px" />
                  ) : (
                    <CheckCircle height="20px" width="20px" />
                  )
                }
                title={`${i + 1}. ${t.label}`}
              />
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
            component={React.forwardRef((props: ReactScrollLinkProps, ref: React.ForwardedRef<unknown>) => (
              <Box ref={ref} sx={{ flex: '1' }}>
                <Link {...props} />
              </Box>
            ))}
            containerId={props.containerId}
            duration={300}
            // The set active tab code was removed for better rendering in fincen but this
            // doesn't update the aria handle so should be updated when we have better
            // performance there and on the monitoring page
            smooth={true}
            spy={true}
            to={t.id}
            sx={{
              textTransform: 'none',
              color: 'text.primary',
              fontWeight: 700,
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              '&.MuiTab-root': {
                minHeight: 0,
                minWidth: 0,
                padding: '15px 22px',
                border: '1px solid #C6CCDA'
              },
              '&.Mui-selected': {
                color: 'text.primary'
              },
              '&.active': {
                opacity: 1,
                borderBottomWidth: '3px',
                borderBottomColor: 'primary.main',
                color: 'primary.main'
              },
              '&:hover': {
                color: 'primary.main'
              },
              '& > .MuiBox-root': {
                padding: '0'
              }
            }}
          />
        );
      })}
    </Tabs>
  );
};

/* Main component */

interface Props {
  steps: StepperStep[];
  manualRefreshFlag?: number;
  stepHandler?: (step: number) => Promise<void>;
  activeTabTitleCallback?: (title: string) => void;
  noHugeMargin?: boolean;
}

/**
 * Return component wrapping StepperStep elements in a scroll view navigated by square tabs.
 *
 * This component makes use of the Adapter pattern to migrate data for our StepperStep component
 * to a new UI/UX experience. Pass it the same "steps" you would a StepperStep. The component is
 * meant to be used in full screen.
 */
export const SquareTabNavigation = (props: Props) => {
  // We need to trigger regenerating content manually, at the appropriate times.
  const [refreshFlag, setRefreshFlag] = useState(0);

  const [tabs, setTabs] = useState<ScrollTab[]>([]);
  const [content, setContent] = useState<JSX.Element>();

  const { manualRefreshFlag } = { ...props };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultStepHandler = async (step: number) => {
    // No-op (for backwards compatibility).
  };

  const handleStep = props.stepHandler ?? defaultStepHandler;
  const scrollingId = 'tab_scrolling_navigation_id';

  const incrementRefreshFlag = () => {
    setRefreshFlag((prev) => {
      return prev + 1;
    });
  };

  /** Transform steps into tabs. */
  useEffect(() => {
    if (props.steps && props.steps.length) {
      const data = props.steps.map((s) => {
        return { id: s.id, label: s.label, isComplete: s.complete } as ScrollTab;
      });

      incrementRefreshFlag();
      setTabs(data);
    } else {
      setTabs([]);
    }
  }, [props.steps]);

  /** Generate content for tabs from the content of steps. */
  useEffect(() => {
    const nodes = props.steps
      ? props.steps.map((s, idx) => {
          const props = { header: '', index: idx, handleStep: handleStep };
          const node = s.ContentElement ? s.ContentElement(props) : null;

          if (node === null) {
            return null;
          } else {
            return (
              <ContainerItem id={tabs[idx]?.id ?? 0} width="100%">
                {node}
              </ContainerItem>
            );
          }
        })
      : [];

    const element = (
      <Box id={scrollingId} sx={{ height: 'calc(100vh - 231px)', overflow: 'auto', width: '100%' }}>
        <Box
          padding={'0 28px'}
          width="100%"
          overflow="visible"
          margin={props.noHugeMargin ? '0 0 120px 0' : '0 0 720px 0'}
        >
          {nodes}
        </Box>
      </Box>
    );

    setContent(element);
  }, [refreshFlag, manualRefreshFlag]);

  return (
    <Box sx={{ marginBottom: '250px', width: '100%' }}>
      <Container margin={0} padding={0} align="center" width="100%">
        <ContainerItem margin={0} padding={0} width="100%" flex align="center">
          <SquareTabs containerId={scrollingId} tabs={tabs} />
        </ContainerItem>
        <div style={{ width: '100%', backgroundColor: '#F9FAFD' }}>
          <Container width="100%" margin={'0'} padding={'0'}>
            {content}
          </Container>
        </div>
      </Container>
    </Box>
  );
};
