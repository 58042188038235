import { OrganizationRoleResolver } from '@gcv/shared';
import { Box, List, useMediaQuery, useTheme } from '@mui/material';
import { TasksApi } from 'api/TasksApi';
import { ReactComponent as CompanyProfileIcon } from 'assets/images/navigation-icons/company-profile.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/navigation-icons/dashboard.svg';
import { ReactComponent as MarketplaceCartIcon } from 'assets/images/navigation-icons/ic-cart.svg';
import { ReactComponent as CloseIcon } from 'assets/images/navigation-icons/ic-cross.svg';
import { ReactComponent as AccountSettingsIcon } from 'assets/images/navigation-icons/ic-settings.svg';
import { ReactComponent as InboxIconNew } from 'assets/images/navigation-icons/inbox-new.svg';
import { ReactComponent as InboxIcon } from 'assets/images/navigation-icons/inbox.svg';
import { ReactComponent as SupportIcon } from 'assets/images/navigation-icons/support.svg';
import { ReactComponent as MyProvidersIcon } from 'assets/images/navigation-icons/toc_24px_outlined.svg';
import { ReactComponent as UsersIcon } from 'assets/images/navigation-icons/users.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getUserStore } from 'stores/UserStore';
import { Divider, ListItemLink } from 'ui/atoms';
import { Drawer } from 'ui/molecules';

interface Props extends Record<string, unknown> {
  handleBankInfoClick: () => void;
  openNavDrawer: boolean;
  permanent: boolean;
  setOpenNavMenu: (isOpen: boolean) => void;
}

export const CrbNavDrawer: React.FC<Props> = useComponent((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispensaryStore = getCrbDispensaryStore();
  const userStore = getUserStore();
  const taskApi = useInjection(TasksApi);

  const resolver = new OrganizationRoleResolver();

  const [activeLink, setActiveLink] = React.useState('');
  const [hasNewTasks, setHasNewTasks] = React.useState(false);

  React.useEffect(() => {
    if (window.location.href.indexOf('/secure/crb/dashboard') > -1) {
      setActiveLink('dashboard');
    } else if (window.location.href.indexOf('/secure/crb/marketplace') > -1) {
      setActiveLink('marketplace');
    } else if (
      window.location.href.indexOf(
        `/secure/crb/${
          dispensaryStore.currentDispensary.id ? `${dispensaryStore.currentDispensary.id}/` : ''
        }my-providers`
      ) > -1
    ) {
      setActiveLink('my-providers');
    } else if (window.location.href.indexOf('/secure/crb/company') > -1) {
      setActiveLink('company');
    } else if (window.location.href.indexOf('/secure/crb/users') > -1) {
      setActiveLink('users');
    } else if (
      window.location.href.indexOf(
        `/secure/crb/${
          dispensaryStore.currentDispensary.id ? `${dispensaryStore.currentDispensary.id}/` : ''
        }inbox`
      ) > -1
    ) {
      setActiveLink('inbox');
    }

    taskApi.getCrbOpenTasksStatus(dispensaryStore.currentDispensary.id).then((response) => {
      setHasNewTasks(response.has_open_tasks);
    });
  }, []);

  const isPlantTouching = getCrbDispensaryStore().currentDispensaryIsPlantTouching;
  const logoLink = React.useMemo(() => {
    return isPlantTouching ? '/secure/crb/dashboard' : '/secure/crb/questionnaires';
  }, [isPlantTouching]);

  const taskLogo = hasNewTasks ? <InboxIconNew /> : <InboxIcon />;

  return (
    <Drawer
      width={isMobile ? '95%' : undefined}
      anchor="left"
      hideBackdrop={props.permanent}
      onClose={() => props.setOpenNavMenu(false)}
      open={props.openNavDrawer}
      permanent={props.permanent}
      dataCy={props.permanent ? 'nav-drawer' : 'mobile-nav-drawer'}
    >
      {!isMobile && (
        <Box
          sx={{
            alignItems: 'center',
            borderRight: '1px solid #bdbdbd',
            display: 'flex',
            height: '73px',
            minHeight: '73px',
            justifyContent: 'center',
            width: '261px'
          }}
        >
          <Link to={logoLink} data-cy="logo-link">
            <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
          </Link>
        </Box>
      )}
      {!isMobile && <Divider />}
      {isMobile && (
        <Box sx={{ textAlign: 'right', margin: '14px 14px 0 0' }}>
          <CloseIcon onClick={() => props.setOpenNavMenu(false)} style={{ cursor: 'pointer' }} />
        </Box>
      )}
      <List component="nav">
        <ListItemLink
          fontSize={isMobile ? '18px' : '14px'}
          height={isMobile ? '56px' : '48px'}
          active={activeLink === 'dashboard'}
          dataCy="dashboard-nav-link"
          icon={<DashboardIcon />}
          onClick={() => setActiveLink('dashboard')}
          text="Dashboard"
          to="/secure/crb/dashboard"
        />

        {resolver.userCanDoAction(
          dispensaryStore.currentDispensary.groups,
          userStore.user,
          'organizational_details_view'
        ) && (
          <ListItemLink
            fontSize={isMobile ? '18px' : '14px'}
            height={isMobile ? '56px' : '48px'}
            active={activeLink === 'marketplace'}
            dataCy=""
            icon={<MarketplaceCartIcon />}
            onClick={() => setActiveLink('marketplace')}
            text="Marketplace"
            to="/secure/crb/marketplace"
          />
        )}

        <ListItemLink
          fontSize={isMobile ? '18px' : '14px'}
          height={isMobile ? '56px' : '48px'}
          active={activeLink === 'inbox'}
          dataCy=""
          icon={taskLogo}
          onClick={() => setActiveLink('inbox')}
          text="Inbox"
          to={`/secure/crb/${
            dispensaryStore.currentDispensary.id ? `${dispensaryStore.currentDispensary.id}/` : ''
          }inbox`}
        />

        {resolver.userCanDoAction(
          dispensaryStore.currentDispensary.groups,
          userStore.user,
          'organizational_details_view'
        ) && (
          <ListItemLink
            fontSize={isMobile ? '18px' : '14px'}
            height={isMobile ? '56px' : '48px'}
            active={activeLink === 'my-providers'}
            dataCy=""
            icon={<MyProvidersIcon />}
            onClick={() => setActiveLink('my-providers')}
            text="My Providers"
            to={`/secure/crb/${
              dispensaryStore.currentDispensary.id ? `${dispensaryStore.currentDispensary.id}/` : ''
            }my-providers`}
          />
        )}

        {resolver.userCanDoAction(
          dispensaryStore.currentDispensary.groups,
          userStore.user,
          'organizational_details_view'
        ) && (
          <ListItemLink
            fontSize={isMobile ? '18px' : '14px'}
            height={isMobile ? '56px' : '48px'}
            active={activeLink === 'company'}
            dataCy="company-profile-nav-link"
            icon={<CompanyProfileIcon />}
            onClick={() => setActiveLink('company')}
            text="Company Profile"
            to="/secure/crb/company"
          />
        )}

        {isMobile && (
          <ListItemLink
            fontSize={isMobile ? '18px' : '14px'}
            height={isMobile ? '56px' : '48px'}
            active={activeLink === 'account-settings'}
            dataCy="user-settings-nav-link"
            icon={<AccountSettingsIcon />}
            onClick={() => setActiveLink('account-settings')}
            text="Account Settings"
            to="/secure/crb/user/settings"
          />
        )}

        {(resolver.userCanDoAction(dispensaryStore.currentDispensary.groups, userStore.user, 'user_add') ||
          resolver.userCanDoAction(
            dispensaryStore.currentDispensary.groups,
            userStore.user,
            'user_remove'
          )) && (
          <ListItemLink
            fontSize={isMobile ? '18px' : '14px'}
            height={isMobile ? '56px' : '48px'}
            active={activeLink === 'users'}
            dataCy="users-nav-link"
            icon={<UsersIcon />}
            onClick={() => setActiveLink('users')}
            text="Users"
            to="/secure/crb/users"
          />
        )}

        <ListItemLink
          fontSize={isMobile ? '18px' : '14px'}
          height={isMobile ? '56px' : '48px'}
          active={false}
          dataCy="support-nav-link"
          icon={<SupportIcon />}
          text="Support"
          to="https://support.greencheckverified.com"
        />
      </List>
    </Drawer>
  );
});
