import { useNavigate } from 'react-router-dom';
import { Button, Container, ContainerItem, Hyperlink } from 'ui';
import { ZeroState } from 'ui/organisms';

const NotFound = () => {
  const navigate = useNavigate();

  const subTitle = (
    <div style={{ font: 'normal normal 400 16px/24px Lato' }}>
      {'For help, visit the '}
      <Hyperlink newTab label="support center" url="https://support.greencheckverified.com/" />
      {', email '}
      <Hyperlink url="mailto:support@greencheckverified.com" label="support@greencheckverified.com" />
      {' or call us at '}
      <Hyperlink url="tel:1-844-544-1940" label="1-844-544-1940" />
      {'.'}
    </div>
  );
  return (
    <ZeroState
      title="We can’t seem to find the page you’re looking for."
      subTitle={subTitle}
      icon={'/img/empty-states/NoResults.svg'}
    >
      <Container>
        <ContainerItem>
          <Button onClick={() => navigate('/')} label={'Back to Home'} color="default-outlined" />
        </ContainerItem>
        <ContainerItem>
          <Button onClick={() => navigate('/logout')} label={'Back to Login'} color="primary" />
        </ContainerItem>
      </Container>
    </ZeroState>
  );
};

export default NotFound;
