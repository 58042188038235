import { Box } from '@mui/system';
import * as React from 'react';
import { Card } from 'ui';

interface Props {
  width?: string;
  children?: React.ReactNode;
}

export const TableContainer: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        '.MuiCardContent-root': {
          padding: '0 !important'
        },
        width: props.width || 'auto'
      }}
    >
      <Card noPadding={true}>{props.children}</Card>
    </Box>
  );
};
