import { PhoneMask } from 'domain/consts/input-masks';
import * as React from 'react';
import { Control, FieldValues, Validate } from 'react-hook-form';
import { InputMasked } from '../InputMasked/input-masked.molecule';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface InputPhoneProps {
  label: string;
  name: string;
  id?: string;
  control?: Control;
  dataCy?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  invalidErrorMessage?: 'invalid';
  background?: 'white' | 'gray';
  placeholder?: string;
  viewOnly?: boolean;
  tooltip?: string | React.ReactElement;
  darkLabel?: boolean;
}

export const InputPhone: React.FC<InputPhoneProps> = (props) => {
  const { rules, invalidErrorMessage, placeholder, ...rest } = props;

  return (
    <InputMasked
      mask={PhoneMask}
      rules={{
        ...rules,
        minLength: { message: invalidErrorMessage || 'invalid', value: 14 }
      }}
      inputProps={{ maxLength: 14 }}
      placeholder={placeholder || '(123) 123-1234'}
      tooltip={props.tooltip}
      darkLabel={props.darkLabel}
      {...rest}
    />
  );
};
