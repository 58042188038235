import { FormControl, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import { Control, Controller, FieldValues, Validate } from 'react-hook-form';
import { Container, ContainerItem, Error, Icon, InputLabel, Select, SelectOption, Text, Tooltip } from 'ui';
import { errorMessages } from 'util/forms.util';
import { Box } from '@mui/material';

interface ValidationRule<T> {
  message: string;
  value: T;
}

interface Props {
  label: string;
  name: string;
  options: SelectOption[];
  noLabel?: boolean;
  control?: Control;
  multiSelect?: boolean;
  defaultValue?: number | string | string[];
  readOnly?: boolean;
  viewOnly?: boolean;
  rules?: {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    minLength?: ValidationRule<number>;
    max?: ValidationRule<number>;
    min?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate?:
      | Validate<string | number, FieldValues>
      | Record<string, Validate<string | number, FieldValues>>;
  };
  tooltip?: string;
  validateError?: string;
  dataCy?: string;
  additionalOnChange?: (e: SelectChangeEvent<any>) => void;
  background?: 'white' | 'grey' | 'dark-grey';
  onBlur?: () => void;
  emptyLabel?: string;
  listItemsWidth?: string;
  addTag?: string;
  useChip?: boolean;
  value?: string | string[];
  placeholder?: string;
  headerComponent?: boolean;
  disableSelected?: boolean;
  darkLabel?: boolean;
  disablePortal?: boolean;
}

export const InputSelect = (props: Props) => {
  return (
    <FormControl fullWidth data-cy={props.dataCy}>
      {!props.noLabel ? (
        <Container align="center" padding={0} width="100%">
          <ContainerItem flex align="center" padding={0} margin={'0 0 6px 0'}>
            <InputLabel
              dataCy={'select-label'}
              label={props.label}
              name={props.name}
              required={props.viewOnly ? false : props.rules?.required?.value}
              readOnly={props.readOnly}
              rules={props.rules}
              viewOnly={props.viewOnly}
              darkLabel={props.darkLabel}
            />
          </ContainerItem>

          {props.tooltip ? (
            <ContainerItem padding={0} align="center" flex margin="-3px 0 4px 2px">
              <Tooltip variant="text" title={props.tooltip} placement="right">
                <Icon type="help" color="primary" />
              </Tooltip>
            </ContainerItem>
          ) : null}
        </Container>
      ) : null}
      {props.viewOnly &&
      !props.name.includes('[') && // nested objects dont work here so exclude them (i.e. props.name === 'locations[0].state')
      (props.control?._formValues[props.name] === undefined ||
        props.control?._formValues[props.name] === '' ||
        props.control?._formValues[props.name] === '--') ? (
        <Box sx={{ p: '0.25rem 0' }}>
          <Text
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '20px'
            }}
            content="--"
          />
        </Box>
      ) : (
        <Controller
          control={props.control}
          name={props.name}
          rules={props.rules}
          defaultValue={props.defaultValue}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Select
                  headerComponent={props.headerComponent ? true : false}
                  additionalOnChange={props.additionalOnChange}
                  background={props.background}
                  dataCy="select-dropdown"
                  label={props.label}
                  multiSelect={props.multiSelect}
                  name={props.name}
                  onBlur={() => {
                    if (props.onBlur) {
                      props.onBlur();
                    }

                    onBlur();
                  }}
                  onChange={onChange}
                  options={props.options}
                  readOnly={props.readOnly}
                  viewOnly={props.viewOnly}
                  value={value !== undefined && value !== 'create-section' ? value : props.value}
                  emptyLabel={props.emptyLabel}
                  listItemsWidth={props.listItemsWidth}
                  addTag={props.addTag ?? ''}
                  useChip={props.useChip}
                  placeholder={props.placeholder}
                  disableSelected={props.disableSelected}
                  disablePortal={props.disablePortal}
                />

                <Error
                  content={error ? `* ${props.label} ${error.message || errorMessages()[error.type]}` : ''}
                />
              </>
            );
          }}
        />
      )}
    </FormControl>
  );
};
