import { CustomField, CustomFieldTypeOptions, CustomSection } from '@gcv/shared';
import { Paper, useMediaQuery } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/images/ic-edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/ic-trash.svg';
import { ReactComponent as MoveControl } from 'assets/images/move-control.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/ic-duplicate.svg';
import { ReactComponent as RulesIcon } from 'assets/images/ic-rules.svg';
import { Box } from '@mui/material';
import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Container, ContainerItem, Header, Text, Tooltip } from 'ui';
import { theme } from 'ui/theme';

interface Props {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
  field: CustomField;
  openFieldModal: (isOpen: boolean, fieldId?: string, sectionId?: string) => void;
  openFieldDeleteModal: (isOpen: boolean, fieldId: string) => void;
  openSmartRuleModal: (field: CustomField) => void;
  viewOnly?: boolean;
  duplicateField?: (type: 'field' | 'section', data: CustomField | CustomSection) => void;
  duplicateItemIds: string[];
}

export const FieldDisplay: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const animation = props.duplicateItemIds.includes(props.field.id) ? '3s linear SMART_RULE_ANIM' : null;

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: '6px',
        p: '10px 16px',
        WebkitAnimation: animation,
        MozAnimation: animation,
        OAnimation: animation,
        animation: animation,
        animationIterationCount: 1,
        '@-webkit-keyframes SMART_RULE_ANIM': {
          from: {
            backgroundColor: '#FFFCE5'
          },
          to: {
            backgroundColor: 'white'
          }
        }
      }}
      data-cy={`field-display-${props.field.id}`}
    >
      <Container align="center" padding={0} nowrap>
        {!props.viewOnly && (
          <ContainerItem padding={0}>
            <div
              {...props.dragHandleProps}
              style={{ height: '20px' }}
              data-cy={`field-${props.field.id}-grab-control`}
            >
              <MoveControl style={{ cursor: 'grab', height: '20px', marginRight: '1rem' }} />
            </div>
          </ContainerItem>
        )}

        <ContainerItem padding={0}>
          <Header
            inline
            content={`${CustomFieldTypeOptions.find((type) => type.value === props.field.type)?.label} : `}
            type="h4"
            dataCy="field-type-name"
          />
        </ContainerItem>

        <ContainerItem grow padding={0} flex>
          {props.field.required && (
            <Text
              inline
              content={'*'}
              type="body2"
              textOverflow="ellipsis"
              sx={{ ml: '10px' }}
              dataCy="field-required"
            />
          )}
          {props.field.label.length > 55 || (isMobile && props.field.label.length > 85) ? (
            <Tooltip title={props.field.label}>
              <Text
                inline
                content={
                  isMobile
                    ? props.field.label.substring(0, 55) + '...'
                    : props.field.label.substring(0, 115) + '...'
                }
                type="body2"
                textOverflow="ellipsis"
                sx={{ ml: props.field.required ? '0px' : '10px' }}
                dataCy="field-title"
              />
            </Tooltip>
          ) : (
            <Text
              inline
              content={props.field.label}
              type="body2"
              textOverflow="ellipsis"
              sx={{ ml: props.field.required ? '0px' : '10px' }}
              dataCy="field-title"
            />
          )}
        </ContainerItem>
        {!props.viewOnly && (
          <>
            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Smart Rules">
                <Box
                  sx={{
                    '&:hover path': { fill: props.field.smart_rule ? '#03A65B' : '#3A3C45' },
                    path: { fill: props.field.smart_rule ? '#03A65B' : '#C6CCDA' }
                  }}
                >
                  <RulesIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => {
                      props.openSmartRuleModal(props.field);
                    }}
                    data-cy="smart-rule-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Duplicate Field">
                <Box
                  sx={{
                    '&:hover path': { fill: '#3A3C45' }
                  }}
                >
                  <DuplicateIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => {
                      if (props.duplicateField) {
                        props.duplicateField('field', props.field);
                      }
                    }}
                    data-cy="duplicate-section-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Edit">
                <Box
                  sx={{
                    '&:hover path': { fill: '#3A3C45' }
                  }}
                >
                  <EditIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => {
                      props.openFieldModal(true, props.field.id, props.field.section);
                    }}
                    data-cy="edit-field-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding={0}>
              <Tooltip placement="bottom" title="Delete">
                <Box
                  sx={{
                    '&:hover path': { fill: '#3A3C45' }
                  }}
                >
                  <DeleteIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => props.openFieldDeleteModal(true, props.field.id)}
                    data-cy="delete-field-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>
          </>
        )}
      </Container>
    </Paper>
  );
};
