import { RequirementId, CommentType } from '@gcv/shared';
import { Box, useMediaQuery } from '@mui/material';
import { useComponent } from 'hooks';
import * as React from 'react';
import { useParams } from 'react-router';
import { Button, Card, Container, ContainerItem, Icon, Text, Tooltip, CommentBadge } from 'ui';
import { theme } from 'ui/theme';
import { isOnboardingDocumentRequirementFulfilled } from 'util/due-diligence.util';
import { RequirementActionsContainer } from './components/requirement-actions-container';
import { ReactComponent as UnfulfilledIcon } from 'assets/images/ic-circle-checkmark-grey.svg';
import { ReactComponent as GreenTickIcon } from 'assets/images/ic-circle-checkmark-green.svg';
import { useInjection } from 'ioc';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';

export interface RequirementCardProps extends Record<string, unknown> {
  requirementId: RequirementId;
}

export const RequirementCardV2: React.FC<RequirementCardProps> = useComponent(({ requirementId }) => {
  const presenter = useInjection(OnboardingPresenter);
  const [loadingAttachments, setLoadingAttachments] = React.useState(false);
  const requirement = presenter.viewModel.onboardingTemplate.requirements[requirementId];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const result = presenter.viewModel.templateResults.requirements_results.find(
    (r) => requirement && r.requirement_id === requirement.requirement_id
  );

  const { bank_id } = useParams() as { bank_id: string };

  if (!requirement) {
    return <></>;
  }

  return (
    <Card dataCy="requirement-card" padding="1.625rem">
      <Container padding={0} justify="space-between" nowrap={!isTablet}>
        <Container padding={0} nowrap>
          <Container padding="10px 8px 8px">
            <ContainerItem padding={0}>
              <Box sx={{ fontSize: '22px' }}>
                {isOnboardingDocumentRequirementFulfilled(result) ? <GreenTickIcon /> : <UnfulfilledIcon />}
              </Box>
            </ContainerItem>
          </Container>
          <Container column>
            <Container
              padding={0}
              align={isTablet ? 'flex-start' : 'center'}
              nowrap={isTablet}
              width={isTablet ? '100%' : 'unset'}
            >
              <Box
                sx={{
                  flex: isMobile ? '1' : 'unset'
                }}
              >
                <ContainerItem padding={0} grow>
                  <Tooltip
                    placement="top"
                    title={requirement.name}
                    disabled={requirement.name.replace(/ /g, '').length > 60 ? false : true}
                  >
                    <Text
                      content={requirement.name}
                      dataCy="requirement-name"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '60ch',
                        fontSize: '18px',
                        fontWeight: 700,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                      }}
                    />
                  </Tooltip>
                </ContainerItem>
              </Box>
              <ContainerItem padding={isTablet ? '4px 0px 0px 8px' : '0px 0px 0px 8px'}>
                <CommentBadge
                  post={{
                    type: CommentType.REQUIREMENT,
                    title: requirement.name,
                    idComponents: {
                      requirementId: requirementId,
                      crbId: presenter.viewModel.dispensary.id,
                      fiId: bank_id
                    }
                  }}
                  thinIcon
                />
              </ContainerItem>
            </Container>
            <Container padding={0} column>
              <ContainerItem
                padding={isMobile ? '0' : '16px 0 0 0'}
                margin={isMobile ? '0 0 0 -36px' : '0'}
                maxWidth={isTablet ? 'unset' : '900px'}
              >
                <Text content={requirement.description} dataCy="requirement-description" />
              </ContainerItem>
              {requirement?.attachments && requirement.attachments?.length > 0 ? (
                <ContainerItem flex align="center" padding={0}>
                  <Button
                    label={'Download Attachment'}
                    color="text"
                    isLoading={loadingAttachments}
                    onClick={() => {
                      setLoadingAttachments(true);
                      presenter.downloadRequirementAttachments(requirement).finally(() => {
                        setLoadingAttachments(false);
                      });
                    }}
                    style={{ textDecoration: 'underline', padding: '0' }}
                  />

                  <Tooltip
                    variant="text"
                    title={
                      'Your financial institution attached files to help you fulfill this requirement. Click to download.'
                    }
                    placement="top"
                  >
                    <Icon type="help" color="primary" />
                  </Tooltip>
                </ContainerItem>
              ) : null}
            </Container>
          </Container>
        </Container>
        <ContainerItem padding="0" width={isTablet ? '100%' : 'unset'}>
          <RequirementActionsContainer result={result} requirement={requirement} />
        </ContainerItem>
      </Container>
    </Card>
  );
});
