import { Box, Card as CardContainer, CSSObject } from '@mui/material';
import * as React from 'react';
import { CardContent, CardFooter, CardHeader, Spinner, ZeroState } from 'ui';

interface Props {
  alignContent?: 'left' | 'center' | 'right';
  dataCy?: string;
  footer?: React.ReactNode;
  footerBorder?: boolean;
  header?: {
    align?: 'flex-start' | 'center' | 'flex-end';
    icon?: string;
    justify?: 'left' | 'center' | 'right';
    title?: string | React.ReactNode;
    subtitle?: string;
    subHeader?: string;
    actions?: React.ReactNode;
    type?: 'h1' | 'h2';
    nowrap?: boolean;
  };
  height?: string;
  isLoading?: boolean;
  maxWidth?: string;
  noPadding?: boolean;
  zeroState?: {
    icon?: string;
    title?: string;
    subtitle?: string;
  };
  padding?: string;
  contentPadding?: string;
  margin?: string;
  noBorder?: boolean;
  headerBorder?: boolean;
  contentOverflow?: string;
  noBorderRadius?: boolean;
  hover?: CSSObject;
  titlePadding?: string;
  animation?: string | null;
  children?: React.ReactNode;
}

export const Card: React.FC<Props> = (props) => {
  const height = props.height ? props.height : 'auto';

  const CardZeroState = () => (
    <ZeroState
      icon={props.zeroState?.icon}
      title={props.zeroState?.title}
      subTitle={props.zeroState?.subtitle}
    />
  );

  return (
    <CardContainer
      data-cy={props.dataCy}
      sx={{
        '&:hover': props.hover ? props.hover : '',
        border: props.noBorder ? 'none' : '1px solid #C6CCDA;',
        borderRadius: props.noBorderRadius ? '0' : '6px',
        display: 'flex',
        flexDirection: 'column',
        height: height,
        overflow: 'hidden',
        width: '100%',
        '& .MuiCardContent-root:first-of-type': {
          pt: props.noPadding ? '0' : props.padding ? props.padding : '1.625rem'
        },
        '& .MuiCardContent-root:last-child': {
          pb: props.noPadding ? '0' : props.padding ? props.padding : '2.5rem'
        },
        margin: props.margin ? props.margin : '0',
        WebkitAnimation: props.animation,
        MozAnimation: props.animation,
        OAnimation: props.animation,
        animation: props.animation,
        animationIterationCount: 1,
        '@-webkit-keyframes SMART_RULE_ANIM': {
          from: {
            backgroundColor: '#FFFCE5'
          },
          to: {
            backgroundColor: 'white'
          }
        }
      }}
      variant="outlined"
    >
      {props.header && (props.header.title || props.header.subtitle || props.header.actions) && (
        <CardHeader
          titlePadding={props.titlePadding}
          align={props.header.align}
          icon={props.header.icon}
          justify={props.header.justify}
          title={props.header.title ? props.header.title : ''}
          subtitle={props.header.subtitle}
          subHeader={props.header.subHeader}
          actions={props.header.actions}
          type={props.header.type}
          nowrap={props.header.nowrap}
          bottomBorder={props.headerBorder}
        />
      )}

      {/* Add a box filler to match header padding when no card children or zerostate are provided */}
      {!props.zeroState && !props.children && <Box sx={{ width: '100%', height: '20px' }} />}

      {(props.zeroState || props.children) && (
        <CardContent
          alignContent={props.alignContent}
          maxWidth={props.maxWidth}
          noPadding={props.noPadding || false}
          padding={props.contentPadding}
          overflow={props.contentOverflow}
        >
          {props.isLoading ? <Spinner /> : props.zeroState ? <CardZeroState /> : props.children}
        </CardContent>
      )}

      {props.footer && (
        <CardFooter noPadding={props.noPadding || false} footerBorder={props.footerBorder}>
          {props.footer}
        </CardFooter>
      )}
    </CardContainer>
  );
};
