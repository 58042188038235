import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { ModalStore } from 'stores/ModalStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { Dialog, Snackbar } from 'ui';
import { MessagesPresenter } from './messages.presenter';

export const Messages = useComponent(() => {
  const presenter = useInjection(MessagesPresenter);

  const modalStore = useInjection(ModalStore);
  const snackbarStore = useInjection(SnackbarStore);

  React.useEffect(() => {
    presenter.init();
  }, []);

  React.useEffect(() => {
    if (presenter.messages.length) {
      for (const message of presenter.messages) {
        snackbarStore.showSnackbar(message.type, message.content, message.onClick);
      }
    }
  }, [presenter.messages]);

  const handleCloseNotification = (event: React.SyntheticEvent<any> | Event, reason: string | undefined) => {
    if (event) {
      event.stopPropagation();
    }

    if (reason === 'clickaway') {
      return;
    }

    snackbarStore.hideSnackbar();
  };

  const handleCloseModal = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    modalStore.hideModal();
  };

  return (
    <>
      <Snackbar
        disableAutoHide={snackbarStore.snackbar.disableAutoHide}
        isOpen={snackbarStore.snackbar.isOpen}
        message={snackbarStore.snackbar.message}
        onClick={snackbarStore.snackbar.onClick}
        onClose={handleCloseNotification}
        type={snackbarStore.snackbar.severity}
        autoHideOverrideInMilliseconds={snackbarStore.snackbar.autoHideOverrideInMilliseconds}
      />

      <Dialog
        action={modalStore.modal.action}
        alignTitle={modalStore.modal.alignTitle}
        dataCy="modal"
        handleClose={handleCloseModal}
        isOpen={modalStore.modal.isOpen}
        onCancel={modalStore.modal.onCancel}
        title={modalStore.modal.title}
      >
        {modalStore.modal.content}
      </Dialog>
    </>
  );
});
