import {
  CrbServiceProvider,
  Dispensary,
  DueDiligenceStatus,
  GREEN_CHECK_ONBOARDING_BANK_ID,
  GetUserConnectedInfoResponse,
  Invitation,
  User
} from '@gcv/shared';
import { DispensariesApi, TemplateApi } from 'api';
import { injectable } from 'inversify';
import { DateTime, Settings } from 'luxon';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { hideLendica, initialiseLendica } from 'third-party-integrations/lendica';
import { updatePendoForCrb } from 'third-party-integrations/pendo';
import { getBannerStore } from './BannerStore';
import { getCrbBankStore } from './CrbBankStore';
import { getUserStore } from './UserStore';

@injectable()
export class CrbDispensaryStore {
  dispensaries = {} as Record<string, Dispensary>;
  limitedDispensaries = [] as GetUserConnectedInfoResponse[];
  invitedDispensaries = [] as Invitation[];
  isLoaded = false;
  isStaffLoaded = false;
  isTemplateLoaded = false;
  isChangingDispensary = false;
  currentDispensaryStaff: User[] = [] as User[];
  currentDispensary: Dispensary = {} as Dispensary;
  currentDispensaryIsPlantTouching = false;

  constructor() {
    makeAutoObservable(this);
  }

  dispensaryApi = new DispensariesApi();
  templateApi = new TemplateApi();
  crbBankStore = getCrbBankStore();
  userStore = getUserStore();
  bannerStore = getBannerStore();
  lendicaOrgIds = [] as string[];

  loadDispensaries = action(async (ids: string[], lendicaOrgIds: string[], userId: string) => {
    const limitedDispensaries = await this.dispensaryApi.getLimitedDispensaries(userId);
    const invitations = (await this.dispensaryApi.getUsersInvitations(userId)).filter((invite) => {
      return invite.status === 'pending';
    });
    runInAction(() => {
      this.limitedDispensaries = limitedDispensaries;
      this.invitedDispensaries = invitations;
      this.lendicaOrgIds = lendicaOrgIds;
      this.isLoaded = true;
    });
  });

  loadDispensaryStaff = action(async () => {
    runInAction(() => {
      this.isStaffLoaded = false;
    });
    const dispensaryStaff = await this.dispensaryApi.getDispensaryStaff(this.currentDispensary.id);
    runInAction(() => {
      this.currentDispensaryStaff = dispensaryStaff;
      this.isStaffLoaded = true;
    });
  });

  updateDispensaryStaff = action((user: User, mergeUser?: boolean) => {
    const existingIndex = this.currentDispensaryStaff.findIndex((u) => u.id === user.id);

    if (existingIndex > -1) {
      const updatedArray = this.currentDispensaryStaff.slice();
      const mergedUser = { ...this.currentDispensaryStaff[existingIndex], ...user };
      updatedArray.splice(existingIndex, 1, mergeUser ? mergedUser : user);
      this.currentDispensaryStaff = updatedArray;
    } else {
      this.currentDispensaryStaff.push(user);
    }
  });

  getDefaultProvider(): CrbServiceProvider {
    if (Object.keys(this.crbBankStore.banks).length === 1) {
      return this.crbBankStore.banks[GREEN_CHECK_ONBOARDING_BANK_ID];
    } else if (
      this.currentDispensary.created_by_org &&
      this.currentDispensary.created_by_org !== GREEN_CHECK_ONBOARDING_BANK_ID &&
      Object.keys(this.crbBankStore.banks).includes(this.currentDispensary.created_by_org)
    ) {
      return this.crbBankStore.banks[this.currentDispensary.created_by_org];
    } else {
      const provider = Object.values(this.crbBankStore.banks)
        .filter((provider) => provider.id !== GREEN_CHECK_ONBOARDING_BANK_ID)
        .sort(
          (a, b) =>
            DateTime.fromISO(a.templates.value.connected_date!).diff(
              DateTime.fromISO(b.templates.value.connected_date!)
            ).milliseconds
        )[0];
      return provider;
    }
  }

  setCurrentDispensary = action(async (id: string) => {
    window.sessionStorage.setItem('currentDispensaryId', id);
    hideLendica();
    // set DateTime's default timezone to be the dispensary's as a catch-all for things we miss
    Settings.defaultZoneName = this.currentDispensary.iana_timezone;

    await runInAction(async () => {
      this.isChangingDispensary = true;
      if (this.dispensaries[id] === undefined) {
        const fullDispensary = await this.dispensaryApi.getDispensary(id);
        this.currentDispensary = fullDispensary;
        this.dispensaries[id] = fullDispensary;
      } else {
        this.currentDispensary = this.dispensaries[id];
      }
    });
    /**
     * Loads the CrbBankStore with all banks related to this CRB. Load banks before calling loadTemplates because
     * loadTemplates will default to a specific template and select the bank for that template
     */
    await this.crbBankStore.loadBanks(this.currentDispensary.id);
    await this.loadDispensaryStaff();

    this.setIsPlantTouching();

    let invitedBy: string;
    const createdByOrgId = this.dispensaries[id].created_by_org as string;
    if (createdByOrgId === GREEN_CHECK_ONBOARDING_BANK_ID) {
      // Invited by GCV Admin
      invitedBy = 'GCV';
    } else if (createdByOrgId === this.dispensaries[id].id) {
      // Self-Invited
      invitedBy = this.dispensaries[id].name;
    } else if (this.crbBankStore.banks[createdByOrgId]) {
      // Invited by FI
      invitedBy = this.crbBankStore.banks[createdByOrgId].orgName;
    }

    const provider = this.getDefaultProvider();
    const activeServiceProviders = Object.values(this.crbBankStore.banks).filter(
      (b) =>
        b.id !== GREEN_CHECK_ONBOARDING_BANK_ID &&
        b.templates.value.onboarding.status === DueDiligenceStatus.BANK_APPROVED
    );
    const sps = activeServiceProviders.map((sp) => {
      const numberOfDaysSinceConnected = Math.floor(
        DateTime.fromISO(sp.templates.value.connected_date).diffNow('days').days
      );
      return {
        id: sp.id,
        status: sp.templates.value.onboarding.status,
        numberOfDaysSinceConnected
      };
    });
    updatePendoForCrb(
      this.dispensaries[id],
      this.userStore.user,
      invitedBy!,
      provider.id,
      provider.templates.value.onboarding.status,
      activeServiceProviders.length,
      sps,
      {
        is_plant_touching: this.currentDispensaryIsPlantTouching
      }
    );

    runInAction(() => {
      this.isChangingDispensary = false;
      this.isLoaded = true;
    });

    if (this.lendicaOrgIds && this.lendicaOrgIds.includes(provider.id)) {
      initialiseLendica(this.dispensaries[id].id, this.dispensaries[id].name);
    } else {
      hideLendica();
    }
  });

  setIsPlantTouching = action(async () => {
    // A CRB is plant touching if they have any service provider with a plant touching template
    const plantTouching = Object.values(this.crbBankStore.banks).some((sp) => sp.plant_touching);
    this.currentDispensaryIsPlantTouching = plantTouching;
  });

  updateDispensary = action(async (dispensary: Dispensary) => {
    this.setIsPlantTouching();
    const newDispensarys = { ...this.dispensaries };
    newDispensarys[dispensary.id] = dispensary;
    if (this.currentDispensary.id === dispensary.id) {
      this.currentDispensary = dispensary;
    }
    this.dispensaries = newDispensarys;
  });

  clearStore = action(() => {
    this.dispensaries = {} as Record<string, Dispensary>;
    this.isLoaded = false;
    this.currentDispensary = {} as Dispensary;
    this.currentDispensaryStaff = [];
    this.currentDispensaryIsPlantTouching = false;
  });
}

let store: CrbDispensaryStore | undefined;

export function getCrbDispensaryStore(): CrbDispensaryStore {
  if (!store) {
    store = new CrbDispensaryStore();
  }

  return store;
}
